import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import { Project } from './types/Project';
import { DataGridTypes } from 'devextreme-react/cjs/data-grid';

async function Projects_BatchUpdate(changes: DataGridTypes.DataChange[]): Promise<string> {

  const url = `${apiBaseUrl}Projects_BatchUpdate`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, changes );
    return data;
  } catch (err) {
    //console.error(err);
    return null;
  }

  
}

export { Projects_BatchUpdate };