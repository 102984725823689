import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Project } from '../api/types/Project';

async function execCloudHostAction(action: string, host: Project): Promise<string> {
  if (action !== 'start' && action !== 'stop') throw new Error();

  const url = `${apiBaseUrl}${action}`;

  const requestData = {
    instance_id: host.projectGuid,
  };

  // call API server
  const data = await sendApiRequestWithAccessToken(url, requestData);
  return data;
}

export { execCloudHostAction };
