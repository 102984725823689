import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Dashboard } from './types/Dashboard';
import useApiLoader from '../lib/useApiLoader';
import { DashboardQuarterlySalesperson } from './types/DashboardQuarterlySalesperson';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function DashboardQuarterlyPayouts_GetBySalesperson(salesPersonList: string, startYear: number, stopYear: number, includeCompletedPayouts: boolean, year: number, quarter: number): Promise<DashboardQuarterlySalesperson[]> {
  console.log('DashboardQuarterlyPayouts_GetBySalesperson: ', salesPersonList, startYear, stopYear, includeCompletedPayouts, year, quarter);
  
  const url = `${apiBaseUrl}DashboardQuarterlyPayouts_GetBySalesperson?salesPersonUserName=${salesPersonList}&startYear=${startYear}&stopYear=${stopYear}&includeCompletedPayouts=${includeCompletedPayouts}&year=${year}&quarter=${quarter}`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useDashboardQuarterlyPayouts_GetBySalesperson(salesPersonList: string, startYear: number, stopYear: number, includeCompletedPayouts: boolean, year: number, quarter: number) {
  const [data, setData] = useState<DashboardQuarterlySalesperson[]>([]);

  const loaderState = useApiLoader<DashboardQuarterlySalesperson[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await DashboardQuarterlyPayouts_GetBySalesperson(salesPersonList,startYear,stopYear, includeCompletedPayouts, year, quarter);
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [salesPersonList, startYear, stopYear, includeCompletedPayouts, year, quarter]
    )
  );

 
  return { data, ...loaderState };
}

export { DashboardQuarterlyPayouts_GetBySalesperson, useDashboardQuarterlyPayouts_GetBySalesperson };
