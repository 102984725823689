import { css } from '@emotion/react';
import { createTheme } from '@mui/material';

const styles = css`
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-inline: 16px;
  min-height: 100vh;
  font-family: var(--app-ui-font-family);
  
  h1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
  }

  img {
    display: inline;
  }

  #dxDeleteButton {  
    background-color: darkred;
    color: white;
    margin-left: 33px;
  }  

  #dxDeleteButton.dx-state-hover {  
    background-color: darkred;
    color: yellow;  
  }  

  #dxCloseButtonDialogTopCorner {  
    background-color: black;
    color: white;
  }  

  #dxRefreshButton {  
    background-color: darkred;
    color: white;
    border-radius: 0px;
    font-weight: 600;
    
  }  

  #dxSaveAddButton {  
    background-color: #032B73;
    color: white;
    border-radius: 0px;
    font-weight: 600;
  }  

  #dxSaveButton {  
    background-color: #032B73;
    color: white;
    border-radius: 0px;
    font-weight: 600;
  }  

  #dxChangePasswordButton {  
    background-color: #032B73;
    color: white;
    border-radius: 0px;
    font-weight: 600;
  }  

  #dxCloseButton {  
    background-color: black;
    color: white;
    border-radius: 0px;
    font-weight: 600;
  }  

  #dxCloseButtonDialogTopCorner.dx-state-hover {  
    background-color: darkred;
    color: white;  
  } 
    
  #dxButtonSignIn {
    background-color: black;
    color: white;
    border-radius: 0px;
    font-weight: 600;
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-inline: 0px;
  }

  #dxButtonForgotPassword {
    background-color: transparent;
    color: #E94A48;
    border-width: 0px;
    font-size: 16px;
    text-decoration: underline;
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-inline: 0px;
  }

  #dxButtonForgotPassword.dx-state-hover {
    font-weight: 800;
  }

  #dxGridProjects .dx-datagrid-headers .dx-row {  
    background-color: black  !important;
    color: white;
    height: 60px;
    white-space: normal;  
  }  


  .dxGridColumnEditable {  
    background-color: #EDEDED;
  }  


  .dxGridColumnEditableOnReview {  
    background-color: #EDEDED;
    color: red;
  }  

  .dx-datagrid-headers .dx-row {  
    background-color: black  !important;
    color: white;
  }  

  .dx-datagrid-headers .dxGridColumnEditable {  
    background-color: black  !important;
    color: white;
  }  
  
  .dx-texteditor-label {  
    border-radius: 0px;
    color: #032B73;
    border-color:blue;
  }  
  .dx-texteditor-input-container {  
    border-radius: 0px;
    border-color: blue;
  }  

 .dx-texteditor-container {  
    border-radius: 0px;
    border-color:blue;
  }  

    
  .dx-datagrid {
      margin-top: 10px;
  }
      
  .dx-datagrid-search-panel {
      margin: 0 0 0 0px;
  }

  .dx-button {
      border-radius: 0px;
  }
`;

const titleStyles = css`
  margin-left: 8px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
`;

const signInButtonStyles = css`
  margin-top: 16px;
  padding-block: 2px;
  font-size: 0.9375rem;
`;


const listAllHostsSwitchStyles = css`
  margin-left: 16px;
  margin-right: 0;
`;

const refreshButtonStyles = css`
  margin-left: 16px;
`;

const progressSpinnerStyles = css`
  margin-left: 16px;
  vertical-align: middle;
`;

const hostListGridStyles = css`
  flex: 1;
  margin-top: 8px;
`;

const muiTheme = createTheme({
  typography: {
    fontFamily: 'var(--app-ui-font-family)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
  },
});

const accountButtonStyles = css`
  margin-left: auto;
  padding: 0;
`;

const actionBarStyles = css`
  margin-top: 8px;
`;


export { styles, titleStyles, signInButtonStyles, muiTheme, accountButtonStyles, actionBarStyles, listAllHostsSwitchStyles, refreshButtonStyles, progressSpinnerStyles, hostListGridStyles };
