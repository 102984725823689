import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { ProjectStatus } from './types/ProjectStatus';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function ProjectStatus_GetAll(): Promise<ProjectStatus[]> {
  
  const url = `${apiBaseUrl}ProjectStatus_GetAll`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useProjectStatus_GetAll() {
  const [data, setData] = useState<ProjectStatus[]>([]);

  const loaderState = useApiLoader<ProjectStatus[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await ProjectStatus_GetAll();
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );

 
  return { data, ...loaderState };
}

export { ProjectStatus_GetAll, useProjectStatus_GetAll };
