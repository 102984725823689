import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import useApiLoader from '../lib/useApiLoader';
import { CommissionPayoutStatus } from './types/CommissionPayoutStatus';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function CommissionPayoutStatuses_GetAll(): Promise<CommissionPayoutStatus[]> {
  
  const url = `${apiBaseUrl}CommissionPayoutStatuses_GetAll`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useCommissionPayoutStatuses_GetAll() {
  const [data, setData] = useState<CommissionPayoutStatus[]>([]);

  const loaderState = useApiLoader<CommissionPayoutStatus[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await CommissionPayoutStatuses_GetAll();
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );

 
  return { data, ...loaderState };
}

export { CommissionPayoutStatuses_GetAll, useCommissionPayoutStatuses_GetAll };
