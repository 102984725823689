import { css } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';

const styles = css`
  animation-duration: 700ms;
`;

function ProgressSpinner({ className = null }) {
  return (
    <CircularProgress
      className={className}
      css={styles}
      variant='indeterminate'
      disableShrink
      size={20}
      thickness={4}
    />
  );
}

export default ProgressSpinner;
