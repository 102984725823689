import { apiBaseUrl } from '../config/appApiConfig';
import { ProjectCommissionPayout } from './types/ProjectCommissionPayout';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import { DataGridTypes } from 'devextreme-react/cjs/data-grid';

async function ProjectsCommissionPayouts_Edit(row: ProjectCommissionPayout): Promise<string> {

  const url = `${apiBaseUrl}ProjectsCommissionPayouts_Edit`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, row );
    return data;
  } catch (err) {
    //console.error(err);
    return null;
  }

  
}

export { ProjectsCommissionPayouts_Edit };