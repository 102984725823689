import { ChangeEvent } from 'react';
import React, { useState } from 'react';

import 'devextreme/dist/css/dx.light.css';
 
import {
    Button,
    DataGrid,
    Column, ColumnFixing,
    Export,
    Editing,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
    DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
    
} from 'devextreme-react/data-grid';


import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Dashboard } from '/api/types/Dashboard';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PCApps-TimesheetDXExport.xlsx');
    });
  });
};


interface CtrGridDashboardsProps {
  dataList: Dashboard[];
  windowWidth: number;
  windowHeight: number;
  onRowClicked?: (_host: Dashboard) => void;
  onRowDoubleClicked?: (_host: Dashboard) => void;
  //onAddRecordClick?: (_host: Dashboard) => void;
  //onEditRecordClick?: (_host: Dashboard) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;

}

const CtrGridDashboards = React.memo(function CtrGridDashboards({
  dataList,
  windowWidth = 1024,
  windowHeight = 800,
  onRowClicked,
  onRowDoubleClicked,
  //onAddRecordClick,
  //onEditRecordClick,
  onRefreshParentComponent,
}: CtrGridDashboardsProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false)

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked)
    onRefreshParentComponent(false)
  }

  function handleRowClick(e: Dashboard) {
    

    //if (isEditableGrid === false) {
    onRowClicked(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
      if (item.name === "searchPanel") {
        item.location = 'before';
      }
      if (item.name === "groupPanel") {
        item.location = 'after';
      }
    })
  }
  // --------------------------------------
  
  const rowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      if (['Q1', 'Q2', 'Q3', 'Q4'].includes(e.data.yearQuarterString)) {
        e.rowElement.style.cssText = 'border-bottom: 1px solid #d1d1d1;';
      }
    }
  }
  
  //In-Line grid editing
  const [rows, setRows] = React.useState(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));
  
  const screenWidthAdjustGridWidth = ((windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%'));
  const screenWidthAdjustGridHeight = ((windowWidth < 900 || windowHeight < 780 ? (windowHeight - 400 - 230).toString() + 'px' : (windowHeight - 400 - 230).toString() + 'px'));

  return (
    <div style={{width: (screenWidthAdjustGridWidth)}}>
      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        // keyExpr="dashboardMonthlyPayoutGuid"
        keyExpr="yearMonthGuid"
        allowColumnResizing={true}
        onExporting={onExporting}
        onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        onRowPrepared={rowPrepared}
        height={(screenWidthAdjustGridHeight)}
        width={'100%'}
        style={{marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={true}
          width={250}
          placeholder="Search..." />
        
        {/* <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column> */}

        <Column dataField="monthPayoutDate" caption='Monthly Payout' width={120} dataType='date' sortOrder='asc'></Column>
        <Column dataField="firstPayoutAmount" caption='1st Payment' width={150} format={'$#,##0.00'}></Column>
        <Column dataField="finalPayoutAmount" caption='Final Payment' width={150} format={'$#,##0.00'}></Column>
        <Column dataField="payoutAmountTotal" caption='Total Payment' width={150} format={'$#,##0.00'}></Column>

        <Column dataField="yearQuarterString" caption='Quarter' width={150} ></Column>
        <Column dataField="quarterlyPayouts" caption='Quarterly Payouts' width={150} format={'$#,##0.00'} ></Column>
        <Column dataField="Spacer" caption='' allowFiltering={false} allowEditing={false} allowHeaderFiltering={false}  ></Column>
                
        <Column dataField="createdOn" caption='Created On' width={150} dataType='datetime' visible={false}></Column>
        <Column dataField="createdBy" caption='Created By' width={120} visible={false}></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime'  visible={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false}></Column>
        
        <ColumnFixing enabled={true} />

        <Summary>
          <TotalItem column="monthPayoutDate" displayFormat='Totals:' />
          <TotalItem column="firstPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="finalPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="payoutAmountTotal" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="quarterlyPayouts" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
        </Summary>
        
        <ColumnChooser
          enabled={true}
          mode={"select"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
            />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={true} allowExportSelectedData={true} />
        

      </DataGrid>

    </div>
  );



});


export default CtrGridDashboards;
