import { apiBaseUrl } from '../config/appApiConfig';
import { DashboardQuarterlySalespersonUpdate } from './types/DashboardQuarterlySalespersonUpdate';
import { ProjectCommissionPayout } from './types/ProjectCommissionPayout';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import { DataGridTypes } from 'devextreme-react/cjs/data-grid';

async function DashboardQuarterlyPayouts_UpdateStatus(param: DashboardQuarterlySalespersonUpdate): Promise<string> {
  console.log('DashboardQuarterlyPayouts_UpdateStatus: ', param);
  const url = `${apiBaseUrl}DashboardQuarterlyPayouts_UpdateStatus`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, param);
    return data;
  } catch (err) {
    //console.error(err);
    return null;
  }
}

export { DashboardQuarterlyPayouts_UpdateStatus };