import React, { useCallback, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { msalLogoutRedirect } from '/lib/msal';
import AppMainMenu from './AppMainMenu';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { BrowserView } from 'react-device-detect';
import { useAppWebsiteAuthorizations } from '../../api/AppWebsiteAuthorizations';
import { PageCanAccessDashboard, PageCanAccessProjects, useAppContextDispatch } from '/lib/appContextStore';
import UserAddEdit from '../security/UserAddEdit';
import type { PCAUsers } from '../../api/types/PCAUsers';
import { PCAUsers_GetForSessionUser } from '/api/PCAUsers_GetForSessionUser';

const styles = css`
  padding: 4px 16px 0px 0px;
  border-bottom: 1px solid #d1d5db;

  nav {
    display: flex;
    align-items: center;

    ul {
      font-size: 0.9375rem;
      font-weight: bold;
    }

    li {
      display: inline-block;
      margin-left: 16px;
    }
  }

  .nav-link {
    padding: 0 4px 12px 4px;
    border-bottom: 2px solid transparent;
    color: #4b5563;
  }

  .nav-link--active {
    padding: 0 4px 12px 4px;
    border-bottom: 2px solid #1976d2;
  }

  img {
    display: inline;
  }
`;

const titleStyles = css`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 90%;
  color: dark-gray;
  
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 12px;
  margin-bottom: 10px;
`;

const logoStyles = css`
  margin-bottom: 10px;
`;
const orgNameButtonStyles = css`
  margin-left: auto;
  margin-right: 0px;
  padding: 0;
  
`;

const accountIconStyles = css`
  font-size: 28px;
  margin-right: 10px;
`;


function AppHeader() {
  const { data: privilegesList } = useAppWebsiteAuthorizations();
  const dispatch = useAppContextDispatch();


  const setIsPagePermissionCheckCompleted = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/isPagePermissionCheckCompleted', payload: value }),
    [dispatch]
  );

  const setCanAccessProjects = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/canAccessProjects', payload: value }),
    [dispatch]
  );

  const setCanAccessProjectsAdminFeatures = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/canAccessProjectsAdminFeatures', payload: value }),
    [dispatch]
  );

  const setCanAccessDashboard = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/canAccessDashboard', payload: value }),
    [dispatch]
  );

  const setCanAccessUtilities = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/canAccessUtilities', payload: value }),
    [dispatch]
  );

  const setCanAccessWebUserSecurity = useCallback(
    (value: boolean) => dispatch({ type: 'Pages/canAccessWebUserSecurity', payload: value }),
    [dispatch]
  );

  function handleStartSignOut() {
    try { localStorage.removeItem('token'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('token'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('logintype'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('msalcheck'); } catch { /* ignore error, continue */ }

    try { msalLogoutRedirect(); } catch { /* ignore error, continue */ }

    window.location.reload();
  }

  function CheckPageAccessPrivileges() {

    if (privilegesList != null) {
      const firstRec = privilegesList[0];
      if (firstRec != null) {
        setCanAccessProjects(firstRec.canAccessProjects);
        setCanAccessProjectsAdminFeatures(firstRec.canAccessProjectsAdminFeatures);
        setCanAccessDashboard(firstRec.canAccessDashboard);
        setCanAccessUtilities(firstRec.canAccessUtilities);
        setCanAccessWebUserSecurity(firstRec.canAccessWebUserSecurity);

        setIsPagePermissionCheckCompleted(true);
      }
    }

    return true;
  }



  function OrgName() {
    let retVal = '';

    if (privilegesList != null) {
      const firstRec = privilegesList[0];

      if (firstRec != null) {
        retVal = firstRec.orgName;
      }
    }
    return retVal;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshParentComponent = (isRefetchData: boolean) => {
  }

  const [isUserAddEditDrawerOpen, setIsUserAddEditDrawerOpen] = useState(false)
  const [currentUserData, setCurrentUserData] = useState<PCAUsers>()
  const handleToggleDrawerCustomerAddEdit = () => {
    setIsUserAddEditDrawerOpen(false)
  }

  const [currentUserGuid, setCurrentUserGuid] = useState('')

  const handleClickMyAccount = async () => {
    const response = await PCAUsers_GetForSessionUser().then(result => { return result; });
    await setCurrentUserData(response);
    await setCurrentUserGuid(response.userGuid);

    setIsUserAddEditDrawerOpen(true);
    setAnchorEl(null);
  }

  const handleDataChangesOnChildAddEditForm = () => {
    
    return;
  }

  const [widthWindow, setWidthWindow] = useState(0)
  
  useEffect(() => {
    function handleResize() {
      setWidthWindow(window.innerWidth)
    }
    
    window.addEventListener("resize", handleResize)
    
    handleResize()
    
    return () => { 
      window.removeEventListener("resize", handleResize)
    }
  }, [setWidthWindow])

  return (

    <header css={styles} >
      <UserAddEdit isDrawerOpen={isUserAddEditDrawerOpen}
        toggleDrawer={handleToggleDrawerCustomerAddEdit}
        isOpenViaMyAccount={true}
        UserGuid={currentUserGuid}
        userData={currentUserData}
        handleSaveStateOnDataChanges={handleDataChangesOnChildAddEditForm}
        onRefreshParentComponent={refreshParentComponent}
        key={currentUserGuid}
      /> 
      <nav>
        <AppMainMenu />
        <img css={logoStyles} src='images/logo.png' alt='PCApps \ PonomaSwapMeet' height={45}  />

        <span css={titleStyles}>Commissions<br/>Manager</span>
        <BrowserView>
          {CheckPageAccessPrivileges()}
          <ul>
              {PageCanAccessProjects() ? (
                <li>
                  <NavLink id='navLinkProjects' className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='home'>
                  Projects
                  </NavLink>
                </li>
              ) : ''}

              {PageCanAccessDashboard() ? (
                <li>
                  <NavLink id='navLinkDashboard' className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='dashboard'>
                  Dashboard
                  </NavLink>
                </li>
              ) : ''}
          </ul>
        </BrowserView>
        
        {widthWindow < 600 
        ? <div></div>
        : 
          <React.Fragment>
            
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' , marginLeft: 'auto', marginRight: '10px'}}>
          <Button id='orgName' name='orgName' css={orgNameButtonStyles} title={OrgName()} variant='text' >
            {OrgName()}
          </Button>
            <Tooltip title='Account settings'>
              <IconButton
                onClick={handleClick}
                size='small'
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClickMyAccount}>
              <AccountCircle css={accountIconStyles} /> My Account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleStartSignOut}>
              <ExitToAppOutlinedIcon css={accountIconStyles} />
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
        }
        

      </nav>
    </header>
  );
}

export default AppHeader;
