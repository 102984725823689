import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { JobTypeProjectCost } from './types/JobTypeProjectCost';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function JobTypesProjectCosts_GetAll(): Promise<JobTypeProjectCost[]> {
  
  const url = `${apiBaseUrl}JobTypesProjectCosts_GetAll`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useJobTypesProjectCosts_GetAll() {
  const [data, setData] = useState<JobTypeProjectCost[]>([]);

  const loaderState = useApiLoader<JobTypeProjectCost[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await JobTypesProjectCosts_GetAll();
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );

 
  return { data, ...loaderState };
}

export { JobTypesProjectCosts_GetAll, useJobTypesProjectCosts_GetAll };
