import { ChangeEvent, useCallback } from 'react';
import React, { useState } from 'react';
import Moment from 'moment';
import { PageCanAccessProjectsAdminFeatures } from '/lib/appContextStore';

import {
  Button,
  DataGrid,
  DataGridRef,
  Column,
  ColumnFixing,
  Export,
  Editing,
  Lookup,
  RequiredRule,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
  Scrolling,
  DataGridTypes,
  Summary,
  TotalItem,
  FilterRow,
  HeaderFilter,
  Search,
  SearchPanel,
} from 'devextreme-react/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Project } from '/api/types/Project';
import { JobType } from '/api/types/JobType';
import { ProjectStatus } from '/api/types/ProjectStatus';
import { Projects_BatchUpdate } from '/api/Projects_BatchUpdate';
import {
  Projects_GetForBrowseListView,
  useProjects_GetForBrowseListView,
} from '/api/Projects_GetForBrowseListView';
import {
  CellPreparedEvent,
  Column as EditingColumn,
  EditingStartEvent,
  InitNewRowEvent,
  RowPreparedEvent,
  RowUpdatingEvent,
} from 'devextreme/ui/data_grid';
import { Salesperson } from '/api/types/Salesperson';
import { ProjectCommissionPayout } from '/api/types/ProjectCommissionPayout';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PCApps-TimesheetDXExport.xlsx');
    });
  });
};

// async function sendBatchRequest(url: string, changes: DataGridTypes.DataChange[]) {
//   const result = await fetch(url, {
//     method: 'POST',
//     body: JSON.stringify(changes),
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8',
//     },
//     credentials: 'include',
//   });

//   if (!result.ok) {
//     const json = await result.json();

//     throw json.Message;
//   }
// }

interface CtrGridProjectsProps {
  className?: string;
  allowInGridEditing?: boolean;
  currentFilterDateStart?: string;
  dataList: Project[];
  jobTypes: JobType[];
  projectStatuses: ProjectStatus[];
  salespersons: Salesperson[];
  projectChangeOrders: Project[];
  windowWidth: number;
  windowHeight: number;
  focusedRowKey: string;
  // onRowChanged?: (_host: Project) => void;
  onRowClicked?: (_host: Project) => void;
  onRowDoubleClicked?: (_host: Project) => void;
  onAddRecordClick?: (_host: Project) => void;
  onEditRecordClick?: (_host: Project) => void;
  onChangeOrderRecordClick?: (_host: Project) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;
}

const CtrGridProjects = React.memo(function CtrGridProjects({
  className,
  allowInGridEditing = true,
  currentFilterDateStart = Moment(new Date(Date.now())).format('YYYY-MM-DD'),
  dataList,
  jobTypes,
  projectStatuses,
  salespersons,
  projectChangeOrders,
  windowWidth = 1024,
  windowHeight = 800,
  focusedRowKey,
  // onRowChanged,
  onRowClicked,
  onRowDoubleClicked,
  onAddRecordClick,
  onEditRecordClick,
  onChangeOrderRecordClick,
  onRefreshParentComponent,
}: CtrGridProjectsProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false);

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked);
    onRefreshParentComponent(false);
  }

  function handleRowClick(e: Project) {
    //if (isEditableGrid === false) {
    onRowClicked(e);
    // onRowChanged(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      if (item.name === 'searchPanel') {
        item.location = 'before';
      }
      if (item.name === 'groupPanel') {
        item.location = 'after';
      }
    });
  }
  // --------------------------------------

  // const projectsStore = createStore({
  //   key: 'ProjectGuid',
  //   loadUrl: `${URL}/Orders`,
  //   onBeforeSend: (method, ajaxOptions) => {
  //     ajaxOptions.xhrFields = { withCredentials: true };
  //   },
  // });

  async function processBatchRequest(
    changes: DataGridTypes.DataChange[],
    component: ReturnType<DataGridRef['instance']>
  ) {
    await Projects_BatchUpdate(changes);
    await component.refresh(true);
    component.cancelEditData();

    onRefreshParentComponent(true);
  }

  const onSaving = (e: DataGridTypes.SavingEvent) => {
    e.cancel = true;

    if (e.changes.length) {
      e.promise = processBatchRequest(e.changes, e.component);
    }
  };

  const onInitNewRow = (e: InitNewRowEvent) => {
    e.data.bookDate = new Date(Date.now());
    e.data.creditPercentage = 1;
    e.data.projectStatusId = 1;
    e.data.actualCollectedAmount = 0;
    e.data.commissionPayoutStatusName = '';
    e.data.notes = '';

    if (salespersons.length === 1)
      e.data.salesPersonUserName = salespersons[0].salesPersonUserName;
  };

  const onEditingStart = (e: EditingStartEvent) => {
    const projectStatusId: number = e.data.projectStatusId;
    const columnName: string = e.column.name;

    if (!isCellEditable(projectStatusId, columnName))
      e.cancel = true;
    
    // if (projectStatusId === 3) {
    //   // status is open
    //   if (columnName !== 'actualCollectedAmount' && columnName !== 'projectStatusId') e.cancel = true;
    // } else if (projectStatusId === 4 || projectStatusId === 5) {
    //   // status is completed/cancelled, do not allow any edit
    //   e.cancel = true;
    // }
  };

  const onFocusedRowChanged = useCallback((e: DataGridTypes.FocusedRowChangedEvent) => {
    // const data = e.row.data;
    // const progress = data.Task_Completion ? `${data.Task_Completion}%` : '';
    // setFocusedRowKey(e.component.option('focusedRowKey'));
    // onRowChanged(e.row.data);
  }, []);

  const rowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      if (e.data.projectStatusId === 2) { // Review Status
        e.rowElement.style.cssText = 'color: #28a805; font-style: italic; font-weight: 600';
      }
    }
  }

  function isCellEditable(projectStatusId: number, columnName: string) {
    if (projectStatusId === 3) { // Review Status
      if (columnName !== 'actualCollectedAmount' && columnName !== 'projectStatusId') 
        return false;
    }
    else if (projectStatusId === 4 || projectStatusId === 5) {
      // status is completed/cancelled, do not allow any edit
      return false;
    }
    return true;
  }

  const cellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data') {
      const projectStatusId: number = e.data.projectStatusId;
      const columnName: string = e.column.dataField;

      if (!isCellEditable(projectStatusId, columnName)) {
        // console.log('cell:', columnName, e.cellElement.style.cssText);
        e.cellElement.style.backgroundColor = 'white';
        // e.cellElement.style.cssText = 'background-color: white';
      }
      // if (projectStatusId === 3) { // Review Status
      //   if (columnName !== 'actualCollectedAmount' && columnName !== 'projectStatusId') 
      //     e.cellElement.style.cssText = 'background-color: white';
      // }
      // else if (projectStatusId === 4 || projectStatusId === 5) {
      //   // status is completed/cancelled, do not allow any edit
      //   e.cellElement.style.cssText = 'background-color: white';
      // }
    }
  }

  //In-Line grid editing
  const isSalespersonLoggedIn = PageCanAccessProjectsAdminFeatures();

  const [rows, setRows] = React.useState<Project[]>(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));

  const screenWidthAdjustGridWidth =
    windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%';
  const screenWidthAdjustGridHeight =
    windowWidth < 900 || windowHeight < 780 ? '550px' : (windowHeight - 320 - 230).toString() + 'px';

  function onChangeOrderClick(e) {
    onChangeOrderRecordClick(e.row);
  }
  // const onChangeOrderClick = useCallback((e) => {
  //   // const clonedItem = { ...e.row.data, ID: getMaxID() };

  //   // setEmployees((prevState) => {
  //   //   const updatedEmployees = [...prevState];
  //   //   updatedEmployees.splice(e.row.rowIndex, 0, clonedItem);
  //   //   return updatedEmployees;
  //   // });

  //   const  clonedItem = { ...e.row.data, projectGuid: null };

  //   // setRows((prevState) => {
  //   //   console.log('onChangeOrderClick setRows()');
  //   //   console.log('prevState1: ', prevState);
  //   //   const updatedProjects = [...prevState];
  //   //   console.log('updatedProjects1: ', updatedProjects);
  //   //   updatedProjects.splice(e.row.rowIndex, 0, clonedItem);
  //   //   console.log('updatedProjects2: ', updatedProjects);
  //   //   console.log('dataList1: ', dataList);
  //   //   dataList.splice(e.row.rowIndex, 0, clonedItem);
  //   //   console.log('dataList2: ', dataList);
  //   //   return updatedProjects;
  //   // });
  //   // console.log('dataList1: ', dataList);
  //   // dataList.splice(e.row.rowIndex, 0, clonedItem);
  //   // console.log('dataList2: ', dataList);

  //   // console.log('rows1: ', rows);
  //   onChangeOrderRecordClick(e.row);
  //   // rows.splice(e.row.rowIndex, 0, clonedItem);
  //   // console.log('rows2: ', rows);

  //   e.event.preventDefault();
  // }, []);

  function MathRoundNumber(n: number) {
    return Math.round(n * 100) / 100;
  }

  function setCellValue(this: EditingColumn, newData: Project, value: number, currentRowData: Project) {
    this.defaultSetCellValue!(newData, value, currentRowData);

    // data input

    // let { sellPrice, costAmount, creditPercentage, collectedPercentage } = currentRowData;
    let sellPrice: number = currentRowData.sellPrice;
    let costAmount: number = currentRowData.costAmount;
    let creditPercentage: number = currentRowData.creditPercentage;
    let collectedPercentage: number = currentRowData.collectedPercentage;
    if (this.name === 'sellPrice') sellPrice = newData.sellPrice;
    else if (this.name === 'costAmount') costAmount = newData.costAmount;
    else if (this.name === 'creditPercentage') creditPercentage = newData.creditPercentage;
    else if (this.name === 'collectedPercentage') collectedPercentage = newData.collectedPercentage;

    // calculations
    const bookedGrossMargin: number = sellPrice - costAmount;
    const payGrossMargin: number = bookedGrossMargin * creditPercentage;
    const grossMarginPercentage: number = sellPrice > 1 ? bookedGrossMargin / sellPrice : 0;
    const totalCommision: number = payGrossMargin * 0.06;
    const firstPayoutAmount: number = MathRoundNumber(totalCommision * 0.5);
    const remainingPayoutAmount: number = totalCommision - firstPayoutAmount;
    
    // calculate collected percentage
    const totalSellPrice: number = projectChangeOrders.reduce((total, project) => {
      return total + project.sellPrice;
    }, sellPrice);
    const actualCollectedAmount: number = currentRowData.actualCollectedAmount;
    const date90PercentCollected: Date = currentRowData.date90PercentCollected;
    collectedPercentage = actualCollectedAmount / totalSellPrice;

    const finalPayoutAmount: number =
      collectedPercentage > 0.89 ? remainingPayoutAmount * 1 : remainingPayoutAmount * 0;

    newData.bookedGrossMargin = bookedGrossMargin;
    newData.payGrossMargin = payGrossMargin;
    newData.grossMarginPercentage = grossMarginPercentage;
    newData.totalCommision = totalCommision;
    newData.firstPayoutAmount = firstPayoutAmount;
    newData.remainingPayoutAmount = remainingPayoutAmount;
    newData.finalPayoutAmount = finalPayoutAmount;

    newData.collectedPercentage = collectedPercentage;
    if (collectedPercentage > 0.9 && date90PercentCollected == null)
      newData.date90PercentCollected = new Date();
    if (collectedPercentage < 0.9 && date90PercentCollected != null) newData.date90PercentCollected = null;
  }

  function setCollectedAmountCellValue(
    this: EditingColumn,
    newData: Project,
    value: number,
    currentRowData: Project
  ) {
    this.defaultSetCellValue!(newData, value, currentRowData);

    // data input
    let actualCollectedAmount: number = newData.actualCollectedAmount;
    const sellPrice: number = currentRowData.sellPrice;
    const totalSellPrice: number = projectChangeOrders.reduce((total, project) => {
      return total + project.sellPrice;
    }, sellPrice);
    const date90PercentCollected: Date = currentRowData.date90PercentCollected;
    if (this.name === 'actualCollectedAmount') actualCollectedAmount = newData.actualCollectedAmount;

    // calculations
    const collectedPercentage: number = actualCollectedAmount / totalSellPrice;

    newData.collectedPercentage = collectedPercentage;
    if (collectedPercentage > 0.9 && date90PercentCollected == null)
      newData.date90PercentCollected = new Date();
    if (collectedPercentage < 0.9 && date90PercentCollected != null) newData.date90PercentCollected = null;
  }

  return (
    <div style={{ width: screenWidthAdjustGridWidth }}>
      <DataGrid
        id="dxGridProjects"
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        repaintChangesOnly={true}
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="projectGuid"
        allowColumnResizing={true}
        columnResizingMode="widget"
        autoNavigateToFocusedRow={true}
        // focusedRowIndex={focusedRowIndex}
        focusedRowKey={focusedRowKey}
        onInitialized={e => setRows(dataList)}
        onExporting={onExporting}
        onInitNewRow={onInitNewRow}
        onEditingStart={onEditingStart}
        onSaving={onSaving}
        onRowClick={e => handleRowClick(e.data)}
        onRowPrepared={rowPrepared}
        onCellPrepared={cellPrepared}
        onFocusedRowChanged={e => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        height={screenWidthAdjustGridHeight}
        width={'100%'}
        wordWrapEnabled={true}
        style={{ marginBottom: '10px', marginRight: '20px' }}>
        <Scrolling mode="infinite" columnRenderingMode="virtual" />

        <HeaderFilter visible={true} />
        <SearchPanel visible={true} width={250} placeholder="Search..." />
        <Editing
          mode="batch"
          useIcons={true}
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
          selectTextOnEditStart={true}
          startEditAction={'click'}
        />
        <Column
          dataField="jobNumber"
          caption="Job #"
          width={120}
          allowEditing={true}
          cssClass="dxGridColumnEditable">
          <RequiredRule />
        </Column>
        <Column
          dataField="projectName"
          caption="Project Name"
          width={200}
          allowEditing={true}
          cssClass="dxGridColumnEditable">
          <RequiredRule />
        </Column>
        <Column
          dataField="bookDate"
          caption="Book Date"
          width={90}
          dataType="date"
          allowEditing={true}
          cssClass="dxGridColumnEditable"></Column>

        <Column
          dataField="salesPersonUserName"
          caption="Salesperson"
          width={120}
          allowEditing={true}
          cssClass="dxGridColumnEditable">
          <Lookup
            dataSource={salespersons}
            displayExpr="salesPersonUserName"
            valueExpr="salesPersonUserName"
          />
          <RequiredRule />
        </Column>
        <Column
          dataField="jobTypeId"
          caption="Job Type"
          width={130}
          dataType="number"
          allowEditing={true}
          cssClass="dxGridColumnEditable">
          <Lookup dataSource={jobTypes} displayExpr="jobTypeName" valueExpr="jobTypeId" />
          <RequiredRule />
        </Column>
        <Column
          dataField="creditPercentage"
          caption="% Credit"
          width={100}
          dataType="number"
          format={'percent'}
          allowEditing={true}
          cssClass="dxGridColumnEditable"
          editorOptions={{ format: 'percent', showClearButton: true }}
          setCellValue={setCellValue}>
          <RequiredRule />
        </Column>
        <Column
          dataField="sellPrice"
          caption="Sell Price"
          width={120}
          dataType="number"
          format={'$#,##0.00'}
          editorOptions={{ format: '#,##0.##' }}
          allowEditing={true}
          cssClass="dxGridColumnEditable"
          setCellValue={setCellValue}>
          <RequiredRule />
        </Column>
        <Column
          dataField="costAmount"
          caption="Cost"
          width={120}
          dataType="number"
          format={'$#,##0.00'}
          editorOptions={{ format: '#,##0.##' }}
          allowEditing={true}
          cssClass="dxGridColumnEditable"
          setCellValue={setCellValue}>
          <RequiredRule />
        </Column>
        <Column
          dataField="bookedGrossMargin"
          caption="Booked Gross Margin"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="payGrossMargin"
          caption="Pay Gross Margin"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="grossMarginPercentage"
          caption="GM %"
          width={70}
          dataType="number"
          format={'#0.00%'}
          allowEditing={false}></Column>
        <Column
          dataField="totalCommision"
          caption="Total Commision"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="firstPayoutAmount"
          caption="First Payout Amount"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="remainingPayoutAmount"
          caption="Remaining Payout Amount"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="collectedPercentage"
          caption="% collected"
          width={100}
          dataType="number"
          format={'#0.00%'}
          allowEditing={false}></Column>
        <Column
          dataField="date90PercentCollected"
          caption="Date 90% collected"
          width={100}
          dataType="date"
          allowEditing={false}></Column>
        <Column
          dataField="finalPayoutAmount"
          caption="Final Payout Amount"
          width={100}
          dataType="number"
          format={'$#,##0.00'}
          allowEditing={false}></Column>
        <Column
          dataField="isChangeOrder"
          caption="C/O?"
          dataType="boolean"
          width={80}
          allowEditing={false}></Column>

        <Column
          dataField="projectStatusId"
          caption="Project Status"
          width={110}
          cssClass={isSalespersonLoggedIn ? 'dxGridColumnEditable' : ''}
          allowEditing={isSalespersonLoggedIn} /*fixedPosition='right' fixed={isSalespersonLoggedIn}*/
          sortOrder='asc'
        >
          <Lookup dataSource={projectStatuses} displayExpr="projectStatusName" valueExpr="projectStatusId" />
          <RequiredRule />
        </Column>

        <Column
          dataField="commissionPayoutStatusName"
          caption="Payout Status"
          width={110}
          allowEditing={false}
          fixedPosition="right"
          fixed={isSalespersonLoggedIn}></Column>
        <Column
          dataField="actualCollectedAmount"
          caption="Collected Amount"
          width={120}
          dataType="number"
          format={'$#,##0.00'}
          editorOptions={{ format: '#,##0.##' }}
          allowEditing={true}
          cssClass="dxGridColumnEditable"
          setCellValue={setCollectedAmountCellValue}></Column>
        <Column
          dataField="notes"
          caption="Notes"
          width={200}
          cssClass={isSalespersonLoggedIn ? 'dxGridColumnEditable' : ''}
          allowEditing={isSalespersonLoggedIn} /*fixedPosition='right' fixed={isSalespersonLoggedIn}*/
        ></Column>

        <Column
          dataField="createdOn"
          caption="Created On"
          width={150}
          dataType="datetime"
          visible={false}></Column>
        <Column dataField="createdBy" caption="Created By" width={120} visible={false}></Column>
        <Column
          dataField="updatedOn"
          caption="Updated On"
          width={150}
          dataType="datetime"
          visible={false}></Column>
        <Column dataField="updatedBy" caption="Updated By" width={120} visible={false}></Column>

        {/* <Column type="buttons" width={110}>
          <Button hint="Add Change Order" icon="copy" onClick={onChangeOrderClick} />
          <Button name="delete" />
        </Column> */}

        <ColumnFixing enabled={true} />
        <Summary>
          <TotalItem column="jobNumber" displayFormat="Totals:" />
          <TotalItem column="sellPrice" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="costAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem
            column="bookedGrossMargin"
            summaryType="sum"
            valueFormat="$#,##0.00"
            displayFormat="{0}"
          />
          <TotalItem column="payGrossMargin" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem
            column="grossMarginPercentage"
            summaryType="avg"
            valueFormat="#0.00%"
            displayFormat="{0}"
          />
          <TotalItem column="totalCommision" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem
            column="firstPayoutAmount"
            summaryType="sum"
            valueFormat="$#,##0.00"
            displayFormat="{0}"
          />
          <TotalItem
            column="remainingPayoutAmount"
            summaryType="sum"
            valueFormat="$#,##0.00"
            displayFormat="{0}"
          />
          <TotalItem
            column="finalPayoutAmount"
            summaryType="sum"
            valueFormat="$#,##0.00"
            displayFormat="{0}"
          />
        </Summary>

        <ColumnChooser enabled={true} mode={'select'}>
          <Position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />

          <ColumnChooserSearch enabled={true} />

          <ColumnChooserSelection allowSelectAll={true} selectByClick={true} recursive={true} />
        </ColumnChooser>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </div>
  );
});

export default CtrGridProjects;
