import { ChangeEvent } from 'react';
import React, { useState } from 'react';

import {
    Button,
    DataGrid,
    Column, ColumnFixing,
    Export,
    Editing,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
    DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
    DataGridRef,
    
} from 'devextreme-react/data-grid';


import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { JobTypeProjectCost } from '/api/types/JobTypeProjectCost';
import { JobTypesProjectCosts_Edit } from '/api/JobTypesProjectCosts_Edit';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PCApps-TimesheetDXExport.xlsx');
    });
  });
};


interface CtrGridCtrGridJobTypesProjectCostsProps {
  dataList: JobTypeProjectCost[];
  windowWidth: number;
  windowHeight: number;
  onRowClicked?: (_host: JobTypeProjectCost) => void;
  onRowDoubleClicked?: (_host: JobTypeProjectCost) => void;
  //onAddRecordClick?: (_host: JobTypeProjectCost) => void;
  //onEditRecordClick?: (_host: JobTypeProjectCost) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;

}

const CtrGridCtrGridJobTypesProjectCosts = React.memo(function CtrGridCtrGridJobTypesProjectCosts({
  dataList,
  windowWidth = 1024,
  windowHeight = 800,
  onRowClicked,
  onRowDoubleClicked,
  //onAddRecordClick,
  //onEditRecordClick,
  onRefreshParentComponent,
}: CtrGridCtrGridJobTypesProjectCostsProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false)

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked)
    onRefreshParentComponent(false)
  }

  function handleRowClick(e: JobTypeProjectCost) {
    

    //if (isEditableGrid === false) {
    onRowClicked(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
        if (item.name === "searchPanel") {
            item.location = 'before';
            }
        if (item.name === "groupPanel") {
            item.location = 'after';
            }
        })
}
  // --------------------------------------

async function saveChanges(changes: DataGridTypes.DataChange[], component: ReturnType<DataGridRef['instance']>) {
  await JobTypesProjectCosts_Edit(changes);
  await component.refresh(true);
  component.cancelEditData();

  onRefreshParentComponent(true);
}
    
  const onSaving = (e: DataGridTypes.SavingEvent) => {
    e.cancel = true;

    if (e.changes.length) {
      e.promise = saveChanges(e.changes, e.component);
    }
  };
  
  
  //In-Line grid editing
  const [rows, setRows] = React.useState(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));
  
  const screenWidthAdjustGridWidth = ((windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%'));
  const screenWidthAdjustGridHeight = ((windowWidth < 900 || windowHeight < 780 ? (windowHeight - 400 - 230).toString() + 'px' : (windowHeight - 400 - 230).toString() + 'px'));

  return (
    <div style={{width: (screenWidthAdjustGridWidth)}}>
      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        repaintChangesOnly={true}
        remoteOperations={false}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="jobTypeProjectCostId"
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnResizingMode='widget'
        onExporting={onExporting}
        onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onSaving={onSaving}
        onToolbarPreparing={onToolbarPreparing}
        height={300}
        width={'100%'}
        style={{marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={false}
          width={250}
          placeholder="Search..." />
        <Editing
        mode="row"
        useIcons={true}
        allowUpdating={true}
        />
        <Column dataField="jobTypeName" caption='Job Type' width={180} visible={true} allowEditing={false}></Column>
        <Column dataField="projectMinimumAmount" caption='Minimum Project Amount' width={150}  format={"$#,##0.00"}  allowEditing={true} cssClass='dxGridColumnEditable'></Column>
        <Column dataField="collectionRate1" caption='1st Collected %' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="commissionRate1" caption='1st Commission Rate' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="collectionRate2" caption='2nd Collected %' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="commissionRate2" caption='2nd Commission Rate' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="collectionRate3" caption='3rd Collected %' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="commissionRate3" caption='3rd Commission Rate' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="collectionRate4" caption='4th Collected %' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="commissionRate4" caption='4th Commission Rate' width={160} format={"#0.00%"} allowEditing={true} cssClass='dxGridColumnEditable' editorOptions={{format: 'percent'}}></Column>
        <Column dataField="Spacer" caption='' allowFiltering={false} allowEditing={false} allowHeaderFiltering={false}  ></Column>
        <Column dataField="createdOn" caption='Created On' width={150} dataType='datetime' allowEditing={false} visible={false}></Column>
        <Column dataField="createdBy" caption='Created By' width={120} visible={false} allowEditing={false}></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime' allowEditing={false} visible={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false} allowEditing={false}></Column>
        
        <ColumnFixing enabled={true} />
        <ColumnChooser
          enabled={false}
          mode={"select"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={false}
            />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={false} allowExportSelectedData={true} />
        

      </DataGrid>

    </div>
  );



});


export default CtrGridCtrGridJobTypesProjectCosts;
