import { Navigate } from 'react-router-dom';
import { PageCanAccessDashboard, PageCanAccessProjects, PageCanAccessWebUserSecurity, PageIsPagePermissionCheckCompleted } from '/lib/appContextStore';


function canAccessPage(Component, privilegeName) {

  const CanAccessPage = props => {

    // not authenticated
    if (PageIsPagePermissionCheckCompleted()) {

      if (privilegeName.startsWith('dashboard')) {
        if (!PageCanAccessDashboard()) return <Navigate to='/notallowed' />;
      }
      else if (privilegeName.startsWith('project')) {
        if (!PageCanAccessProjects()) return <Navigate to='/notallowed' />;
      }
      else if (privilegeName.startsWith('security')) {
        if (!PageCanAccessWebUserSecurity()) return <Navigate to='/notallowed' />;
      }

      return <Component {...props} />;
    }
  }


  CanAccessPage.displayName = `CanAccessPage(${Component.displayName || Component.name})`;
  return CanAccessPage;
}

export { canAccessPage };
