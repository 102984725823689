import { useCallback, useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import ResponsiveBox, {
  Row, Col, Location, 
} from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';

import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import RadioGroup, { RadioGroupTypes } from 'devextreme-react/radio-group';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import RefreshButton from '../shared/RefreshButton';
import ProgressSpinner from '../shared/ProgressSpinner';
import ControlGridDashboards from './CtrGridDashboards';
import ControlGridDashboardSalesperson from './CtrGridDashboardSalesperson';
import ControlGridDashboardQuarterlySalesperson from './CtrGridDashboardQuarterlySalesperson';

import ControlActionDialogConfirmHost, { useConfirmHostActionDialog } from '../shared/CtrActionDialogConfirmHost';
import { useDashboardMonthlyPayouts_Get } from '../../api/DashboardMonthlyPayouts_Get';


import useHostAction from '../../lib/useHostAction';
import type { Dashboard } from '../../api/types/Dashboard';

import { canAccessPage } from '/lib/canAccessPage';
import { useDashboardMonthlyPayouts_GetBySalesperson } from '/api/DashboardMonthlyPayouts_GetBySalesperson';
import { CheckBox, CheckBoxTypes } from 'devextreme-react/check-box';
import { useSalesperson_GetAll } from '/api/Salesperson_GetAll';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { useDashboardQuarterlyPayouts_GetBySalesperson } from '/api/DashboardQuarterlyPayouts_GetBySalesperson';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { DashboardQuarterlyPayouts_UpdateStatus } from '/api/DashboardQuarterlyPayouts_UpdateStatus';
import { DashboardQuarterlySalespersonUpdate } from '/api/types/DashboardQuarterlySalespersonUpdate';

const progressSpinnerStyles = css`
  margin-left: 16px;
  vertical-align: middle;
`;


function DashboardBrowse() {
  // global context
  const currentYear: number = new Date().getFullYear();
  const currentQuarter: number = new Date().getMonth() / 3;
  // const [filterSalespersonList, setFilterSalespersonList] = useState('');
  const [filterSalesPersonUserName, setFilterSalesPersonUserName] = useState('');
  const [filterStartYear, setFilterStartYear] = useState<number>(currentYear - 1);
  const [filterStopYear, setFilterStopYear] = useState<number>(currentYear);
  const [filterIncludeCompletedPayouts, setFilterIncludeCompletedPayouts] = useState<boolean>(false);
  const [selectYear, setSelectYear] = useState<number>(currentYear);
  const [selectQuarter, setSelectQuarter] = useState<number>(currentQuarter);

  const GridRowClicked = (ts: Dashboard) => {
    const monthPayoutDate = new Date(ts.monthPayoutDate);
    const year: number = monthPayoutDate?.getFullYear();
    const quarter: number = Math.floor((monthPayoutDate?.getMonth()) / 3) + 1;
    console.log('quarter: ', quarter);
    
    setSelectYear(year);
    setSelectQuarter(quarter);

    refetchDashboardsQuarterlyBySalesperson();
  };

  const {
    data: Salespersons,
    isLoading: loadingSalespersons,
    refetch: refetchSalespersons,
  } = useSalesperson_GetAll();

  const {
    data: Dashboards,
    isLoading: loadingDashboards,
    refetch: refetchDashboards,
  } = useDashboardMonthlyPayouts_Get(filterSalesPersonUserName, filterStartYear, filterStopYear);

  const {
    data: DashboardsBySalesperson,
    isLoading: loadingDashboardsBySalesperson,
    refetch: refetchDashboardsBySalesperson,
  } = useDashboardMonthlyPayouts_GetBySalesperson(filterSalesPersonUserName, filterStartYear, filterStopYear);

  const {
    data: DashboardsQuarterlyBySalesperson,
    isLoading: loadingDashboardsQuarterlyBySalesperson,
    refetch: refetchDashboardsQuarterlyBySalesperson,
  } = useDashboardQuarterlyPayouts_GetBySalesperson(filterSalesPersonUserName, filterStartYear, filterStopYear, filterIncludeCompletedPayouts, selectYear, selectQuarter);

  // host action
  const { executeAction: handleExecuteHostAction, isLoading: loadingHostAction } =
    useHostAction(refetchDashboards);

  // confirm host action dialog
  const { confirmHostActionState, hideConfirmHostActionDialog } = useConfirmHostActionDialog();

  const underConstructionButtonClick = useCallback(() => {
    const message = `Under Construction - Specification File Needed`;
    notify(
      {
        message,
        width: 400,
        position: {
          my: 'center top',
          at: 'center top',
        },
      },
      'info',
      3000
    );
  }, []);

  const setAlternatingRowsBackground = (gridCell, excelCell) => {
    if (gridCell.rowType === 'header' || gridCell.rowType === 'data') {
      if (excelCell.fullAddress.row % 2 === 0) {
        excelCell.fill = {
          type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' }, bgColor: { argb: 'D3D3D3' },
        };
      }
    }
  };

  const payoutGridRef1 = useRef<DataGridRef>(null);
  const generateQuarterlyPayout = useCallback(() => {
    const workbook = new Workbook();
    const payoutSheet = workbook.addWorksheet('Payouts');

    if (payoutGridRef1 !== null) {
      exportDataGrid({
        worksheet: payoutSheet,
        component: payoutGridRef1.current.instance(),
        topLeftCell: { row: 1, column: 1 },
        customizeCell: ({ gridCell, excelCell }) => {
          setAlternatingRowsBackground(gridCell, excelCell);
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PowersLA-QuarterlyPayouts.xlsx');
        });
      });

      saveChanges();
    }
  }, []);
  
  async function saveChanges() {
    const param : DashboardQuarterlySalespersonUpdate = {
      salesPersonUserName: filterSalesPersonUserName, 
      year: selectYear, 
      quarter: selectQuarter
    }
    const results = await DashboardQuarterlyPayouts_UpdateStatus(param);

    if (!results) {
      alert('Save failed');
      return;
    }

    return;
  }

  const loadingData = loadingDashboards || loadingHostAction;

  const activeStatus = ['Active', 'Inactive', 'Both'];
  const showYearRecords: number[] = Array.from({ length: 6 }, (_, i) => currentYear + 1 - i);
  // [
  //   2020,
  //   2021,
  //   2022,
  //   2023,
  //   2024,
  // ];

  const salesPersonValueChanged = useCallback((e: ValueChangedEvent) => {
    setFilterSalesPersonUserName(e.value || []);
  }, []);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWindowWidth]);

  const screenByWidthFilterSection = (width: number) => (width < 900 ? 'sm' : 'lg');
  const screenByWidthPaymentTab = (width: number) => (width < 900 ? 'sm' : 'lg');
  const screenWidthAdjustFilterContainer = windowWidth < 900 ? 1050 : 1060;
  const screenWidthAdjustFilterTextInput = windowWidth < 900 ? '125px' : '125px';
  const screenWidthAdjustWidthDetailTab =
    windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%';
  const screenWidthAdjustHeightDetailTab = windowWidth < 900 ? '420' : '420';
  const screenWidthAdjustBody =
    windowWidth !== 0 ? (((windowWidth - 40) / windowWidth) * 100).toString() + 'vw' : '96vw';

  return (
    <div style={{ padding: '0px', margin: '0px', width: screenWidthAdjustBody }}>
      <h1>Dashboard</h1>

      <ResponsiveBox screenByWidth={screenByWidthFilterSection} width={screenWidthAdjustFilterContainer}>
        <Row ratio={1}></Row>
        <Row ratio={1}></Row>

        <Col ratio={1} baseSize={150}></Col>
        <Col ratio={1} baseSize={100}></Col>
        <Col ratio={1} baseSize={100}></Col>
        <Col ratio={1} baseSize={120}></Col>
        <Col ratio={1} baseSize={450}></Col>

        <Item>
          <Location screen="lg" row={0} col={0}></Location>
          <Location screen="sm" row={0} col={0}></Location>
          <div>
            <SelectBox
              dataSource={Salespersons}
              value={filterSalesPersonUserName}
              valueExpr={'salesPersonUserName'}
              displayExpr={'salesPersonUserName'}
              inputAttr={{ 'aria-label': 'Simple Product' }}
              defaultValue={''}
              onValueChanged={salesPersonValueChanged}
              searchEnabled={true}
              searchMode={'startswith'}
              searchExpr={'salesPersonUserName'}
              label="Salesperson(s)"
              width={175}
            />
          </div>
        </Item>

        <Item>
          <Location screen="lg" row={0} col={1}></Location>
          <Location screen="sm" row={0} col={1}></Location>
          <div>
            <SelectBox
              items={showYearRecords}
              inputAttr={{ 'aria-label': 'Simple Product' }}
              acceptCustomValue={true}
              defaultValue={filterStartYear}
              onValueChanged={({ component }) => {
                const selectedYear: number = component.option('selectedItem');
                setFilterStartYear(selectedYear);
                if (selectedYear > filterStopYear) setFilterStopYear(selectedYear);
              }}
              label="Start Year"
              width={screenWidthAdjustFilterTextInput}
            />
          </div>
        </Item>

        <Item>
          <Location screen="lg" row={0} col={2}></Location>
          <Location screen="sm" row={0} col={2}></Location>
          <div>
            <SelectBox
              items={showYearRecords}
              inputAttr={{ 'aria-label': 'Simple Product' }}
              acceptCustomValue={true}
              defaultValue={filterStopYear}
              onValueChanged={useCallback(({ component }) => {
                const selectedYear: number = component.option('selectedItem');
                setFilterStopYear(selectedYear);
                if (selectedYear < filterStartYear) setFilterStartYear(selectedYear);
              }, [])}
              label="Stop Year"
              width={screenWidthAdjustFilterTextInput}
            />
          </div>
        </Item>

        <Item>
          <Location screen="lg" row={0} col={3}></Location>
          <Location screen="sm" row={0} col={3}></Location>
          <div style={{ marginTop: '5px' }}>
            <RefreshButton disabled={loadingData} onClick={refetchDashboards} />
            <ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} />
          </div>
        </Item>

        <Item>
          <Location screen="lg" row={0} col={4}></Location>
          <Location screen="sm" row={0} col={4}></Location>
          <div style={{ marginTop: '5px' }}>
            <Button
              width={200}
              id={'dxGenerateQuarterlyPayoutButton'}
              text="contained"
              type="default"
              onClick={generateQuarterlyPayout}>
              Generate Quarterly Payout
            </Button>
            <CheckBox
              // defaultValue={false}
              value={filterIncludeCompletedPayouts}
              onValueChanged={useCallback((args: CheckBoxTypes.ValueChangedEvent) => {
                setFilterIncludeCompletedPayouts(args.value);
                refetchDashboardsQuarterlyBySalesperson();
              }, [])}
              elementAttr={{ 'aria-label': 'Unchecked' }}
              text="Included Already Completed Payouts"
              style={{ paddingLeft: '10px'}}
            />
          </div>
          
        </Item>
      </ResponsiveBox>

      <ControlGridDashboards
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        dataList={Dashboards}
        onRowClicked={GridRowClicked}
      />
      <div style={{ marginTop: '10px', marginBottom: '0px' }}>
        <TabPanel
          width={screenWidthAdjustWidthDetailTab}
          height={screenWidthAdjustHeightDetailTab}
          animationEnabled={true}
          swipeEnabled={true}
          tabsPosition={'top'}
          stylingMode={'primary'}>
          <Item title="by Salesperson" icon="card">
            <ControlGridDashboardSalesperson
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              dataList={DashboardsBySalesperson}
            />
          </Item>
          <Item title="Quarterly Payouts" icon="card">
            <ControlGridDashboardQuarterlySalesperson
              payoutGridRef={payoutGridRef1}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              dataList={DashboardsQuarterlyBySalesperson}
            />
          </Item>
        </TabPanel>
      </div>

      <ControlActionDialogConfirmHost
        hideDialog={hideConfirmHostActionDialog}
        onExecute={handleExecuteHostAction}
        action={confirmHostActionState?.action}
        host={confirmHostActionState?.host}
      />
    </div>
  );
}

//export default DashboardBrowse;
export default canAccessPage(DashboardBrowse, 'Dashboards');