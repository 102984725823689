import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { PCAUsers } from './types/PCAUsers';

async function PCAUsers_AddEdit(ts: PCAUsers): Promise<string> {

  const url = `${apiBaseUrlSecurity}PCAUsers_AddEdit`;
  
  
  // const tsDate = new Date(ts.quoteEntryDate)

  // ts.quoteEntryDate = new Date(tsDate.getFullYear(), tsDate.getMonth(), tsDate.getDate(), 0, 0, 0, 0)

  if (ts.userGuid === '') {
    ts.userGuid = '{00000000-0000-0000-0000-000000000000}';
  }
  

  // const requestData = {
  //   userGuid: ts.userGuid,
  //   userName: ts.userName,
  //   firstName: ts.firstName,
  //   middleName: ts.middleName,
  //   lastName: ts.lastName,
  //   employeeId: ts.employeeId,
  //   employeeTitle: ts.employeeTitle,
  //   defaultLanguageId: ts.defaultLanguageId,
  //   emailAddress: ts.emailAddress,
  //   emailBodyFormatPreference: ts.emailBodyFormatPreference,
  //   phoneWork: ts.phoneWork,
  //   phoneHome: ts.phoneHome,
  //   phoneMobile: ts.phoneMobile,
  //   isEnabled: ts.isEnabled,
  //   isLocked: ts.isLocked
  // };

  //function to calculateMaxNumber
  

  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, ts);
    
    return data;
  } catch (err) {
    return null;
  }

  
  
}

export { PCAUsers_AddEdit };
