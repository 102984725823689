import { Button } from 'devextreme-react/button';

function RefreshButton({ disabled = false, onClick }) {
  return (
    <Button
      width={100}
      id={'dxRefreshButton'}
      text="contained"
      type="default"
      disabled={disabled}
      onClick={onClick}>
      Refresh
    </Button>
  );
}

export default RefreshButton;
