import { Button, ThemeProvider, createTheme } from '@mui/material';
import { css } from '@emotion/react';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  font-family: var(--app-ui-font-family);

  main {
    padding: 120px;
    border: 1px solid #d1d5db;
    background-color: #fff;
  }

  h1 {
    display: flex;
    align-items: center;
  }

  img {
    display: inline;
  }
`;

const titleStyles = css`
  margin-left: 8px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
`;

const signInButtonStyles = css`
  margin-top: 16px;
  background-color: blue;
  padding-block: 2px;
  width: 300px;
  font-size: 0.8375rem;
`;

const signInButtonPCAppsStyles = css`
  margin-top: 8px;
  background-color: white;
  border-color: navy;
  border-width: 1px;
  border: solid;
  color: navy;
  padding-block: 1px;
  width: 300px;
  font-size: 0.8375rem;
`;
const muiTheme = createTheme({
  typography: {
    fontFamily: 'var(--app-ui-font-family)',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
  },
});

// import { muiTheme, signInButtonStyles, styles, titleStyles } from './shared/Styles';

interface LoginPageAzureADMSALProps {
  onStartSignIn: () => void,
  onStartSignInPCApps: () => void;
}

function LoginPageAzureADMSAL({ onStartSignIn, onStartSignInPCApps }: LoginPageAzureADMSALProps) {
  return (
    <ThemeProvider theme={muiTheme}>
      <div css={styles}>
        <main>
          <h1>
            <img src='images/logo.png' width='90' alt='PCApps' />
            <span css={titleStyles}>React Template</span>
          </h1>
          <Button
            id='signInViaMSAL'
            css={signInButtonStyles}
            variant='contained'
            size='small'
            disableElevation
            onClick={onStartSignIn}>
            Sign in with Microsoft Azure Account
          </Button>
          <br />
          <Button
            id='signInViaPCApps'
            css={signInButtonPCAppsStyles}
            variant='contained'
            size='small'
            disableElevation
            onClick={onStartSignInPCApps}>
            Sign in with PCApps Authorized Account
          </Button>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default LoginPageAzureADMSAL;
