import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { PCAUsers } from './types/PCAUsers';

async function PCAUsers_Delete(ts: PCAUsers): Promise<string> {

  const url = `${apiBaseUrlSecurity}PCAUsers_Delete?userGuid=${ts.userGuid}`;
  const requestData = { };

  // call API server
  const data = await sendApiRequestWithAccessToken(url, requestData);
  return data;
}

export { PCAUsers_Delete };
