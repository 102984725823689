import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import { DataGridTypes } from 'devextreme-react/cjs/data-grid';

async function JobTypesProjectCosts_Edit(changes: DataGridTypes.DataChange[]): Promise<string> {

  const url = `${apiBaseUrl}JobTypesProjectCosts_Edit`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, changes );
    return data;
  } catch (err) {
    //console.error(err);
    return null;
  }

  
}

export { JobTypesProjectCosts_Edit };