import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';

async function Projects_SubmitForReview(projectGuid: string): Promise<any> {

  const url = `${apiBaseUrl}Projects_SubmitForReview?projectGuid=${projectGuid}`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, null );
    return data.response;
  } catch (err) {
    //console.error(err);
    return null;
  }
}

export { Projects_SubmitForReview };