import { useState } from 'react';
import { css } from '@emotion/react';
import type { } from '@mui/x-data-grid/themeAugmentation';

import { useMediaQuery, Box } from '@mui/material'
// import ControlDropdownUsers from './CtrDropdownUsers';
import RefreshButton from '../shared/RefreshButton';
import ProgressSpinner from '../shared/ProgressSpinner';
import ControlGridUsers from './CtrGridUsers';
import ControlActionDialogConfirmHost, { useConfirmHostActionDialog } from '../shared/CtrActionDialogConfirmHost';

// import { useActiveUserWithShowAll, useAppContextDispatch, useAppContextStore } from '/lib/appContextStore';
// import { usePCAUsers_GetWithShowAll } from '../../api/PCAUsers_GetWithShowAll';

import { usePCAUsers_GetForUsersBrowse } from '../../api/PCAUsers_GetForUsersBrowse';

import useHostAction from '../../lib/useHostAction';
import type { PCAUsers } from '../../api/types/PCAUsers';

import { canAccessPage } from '/lib/canAccessPage';
import UserAddEdit from './UserAddEdit';
import { PCAUsers_GetForGuid } from '/api/PCAUsers_GetForGuid';



const progressSpinnerStyles = css`
  margin-left: 16px;
  vertical-align: middle;
`;

const hostListGridStyles = css`
  flex: 1;
  margin-top: 8px;
`;

function UserBrowse() {
  // global context

  // const dispatch = useAppContextDispatch();

  const [isUserAddEditDrawerOpen, setIsUserAddEditDrawerOpen] = useState(false)
  const [currentUserData, setCurrentUserData] = useState<PCAUsers>()

  const [dataLastRefreshedOn, setDataLastRefreshedOn] = useState(new Date(Date.now()));
  const [lastRefreshedOn, setLastRefreshedOn] = useState(new Date(Date.now()));
  const refreshParentComponent = async () => {
    const t = new Date(lastRefreshedOn);
    t.setSeconds(t.getSeconds() + 3);
    setLastRefreshedOn(t);

    setDataLastRefreshedOn(t);
    await refetchPCAUsers();
  }

  const [isNeedToSaveChangesOnChildAddEditForm, setIsNeedToSaveChangesOnChildAddEditForm] = useState(false)
  const handleDataChangesOnChildAddEditForm = (isDataChanged: boolean) => {
    setIsNeedToSaveChangesOnChildAddEditForm(isDataChanged)
  }

  const clickAddUserOpen = async () => {

    const newUser: PCAUsers = {
      userGuid: '',
      userName: '',
      firstName: '',
      lastName: '',
      isDefault: false,
      sortOrder: 0,
      defaultLanguageId: 1,
      middleName: '',
      emailAddress: '',
      emailBodyFormatPreference: 'HTML',
      phoneHome: '',
      phoneWork: '',
      phoneMobile: '',
      employeeId: '',
      employeeTitle: '',
      isEnabled: true,
      isLocked: false,
      createdOn: new Date(),
      createdBy: '',
      updatedOn: new Date(),
      updatedBy: '',
      password: '',
      officeLocation: '',
    }
    await setCurrentUserData(newUser);
    await setCurrentUserGuid('');
    setIsUserAddEditDrawerOpen(true);
  }

  const clickEditUserOpen = async (ts: PCAUsers) => {
    if (isUserAddEditDrawerOpen) {
      if (isNeedToSaveChangesOnChildAddEditForm) {
        alert('Please save or cancel changes on edit form before proceeding.')
        return;
      }
    }
    const response = await PCAUsers_GetForGuid(ts.userGuid).then(result => { return result; });
    await setCurrentUserData(response);
    await setCurrentUserGuid(ts.userGuid);
    setIsUserAddEditDrawerOpen(true);

  }

  const GridRowClicked = (ts: PCAUsers) => { if (isUserAddEditDrawerOpen) { setCurrentUserGuid(ts.userGuid); setCurrentUserData(ts); } }
  const handleToggleDrawerQuoteAddEdit = () => {
    setIsUserAddEditDrawerOpen(false)
  }

  const [currentUserGuid, setCurrentUserGuid] = useState('')
  const [isNeedToRefreshDrawer, setIsNeedToRefreshDrawer] = useState(true)
  const handleEditUser = (event) => { setCurrentUserGuid(event.target.value) }

  // load Users list
  const {
    data: PCAUsers,
    isLoading: loadingPCAUsers,
    refetch: refetchPCAUsers,
  } = usePCAUsers_GetForUsersBrowse();

  const { executeAction: handleExecuteHostAction, isLoading: loadingHostAction } = useHostAction(
    refetchPCAUsers
  );

  // confirm host action dialog
  const { confirmHostActionState, showConfirmHostActionDialog, hideConfirmHostActionDialog } =
    useConfirmHostActionDialog();

  const loadingData = loadingPCAUsers;

  const narrowBreakpoint = '(max-width: 600px)';
  const mediumBreakpoint = '(max-width: 1080px)';

  const isNarrow = useMediaQuery(narrowBreakpoint);
  const isMedium = useMediaQuery(mediumBreakpoint);


  return (
    <div  >
        <h1>Users</h1>
        <Box sx={{ height: isNarrow ? '150px' : (isMedium ? '100px' : '50px') }}
        >
          <RefreshButton disabled={loadingData} onClick={refetchPCAUsers} />
          <ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} />
        </Box>
        <ControlGridUsers
          dataList={PCAUsers}
          // onHostAction={showConfirmHostActionDialog}
          onEditRecordClick={clickEditUserOpen}
          onAddRecordClick={clickAddUserOpen}
          onRowClicked={GridRowClicked}
        />
        <UserAddEdit isDrawerOpen={isUserAddEditDrawerOpen}
          toggleDrawer={handleToggleDrawerQuoteAddEdit}
          UserGuid={currentUserGuid}
          userData={currentUserData}

          //isNeedToRefreshDrawerData={isNeedToRefreshDrawer}
          handleSaveStateOnDataChanges={handleDataChangesOnChildAddEditForm}
          onRefreshParentComponent={refreshParentComponent}
          key={currentUserGuid}
          isOpenViaMyAccount={false}
        />,

        <ControlActionDialogConfirmHost
          hideDialog={hideConfirmHostActionDialog}
          onExecute={handleExecuteHostAction}
          action={confirmHostActionState?.action}
          host={confirmHostActionState?.host}

        />
      
    </div>
  );
}

//export default UserBrowse;
export default canAccessPage(UserBrowse, 'security');