import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { SalespersonJobCommissionRate } from './types/SalespersonJobCommissionRate';
import useApiLoader from '../lib/useApiLoader';

async function SalespersonJobCommissionRates_GetAll(): Promise<SalespersonJobCommissionRate[]> {
  
  const url = `${apiBaseUrl}SalespersonJobCommissionRates_GetAll`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useSalespersonJobCommissionRates_GetAll() {
  const [data, setData] = useState<SalespersonJobCommissionRate[]>([]);

  const loaderState = useApiLoader<SalespersonJobCommissionRate[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await SalespersonJobCommissionRates_GetAll();
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );

 
  return { data, ...loaderState };
}

export { SalespersonJobCommissionRates_GetAll, useSalespersonJobCommissionRates_GetAll };
