import type { IPublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import type { IMsalContext } from '@azure/msal-react';
import { msalAppApiScope } from '/config/msalConfig';
import { sendApiRequest } from '../api/sendApiRequest';

//import { isUsePCAppsSecurity } from '../config/appApiConfig';

let msalInstance: IPublicClientApplication;
let loginRedirectStarted = false;

function setMsalInstance(instance: IPublicClientApplication) {
  msalInstance = instance;
}

function isUsingPCAppsSecurity() {
  let isPCApps = false;
  const loginType = localStorage.getItem('logintype') ?? ''; //) ? localStorage.getItem('logintype') === 'pca' ? isUsing = true : false : isUsing=false
  //console.log({loginType});

  if (loginType === 'pcapps')
    isPCApps = true;

  return isPCApps;
}

async function requestApiAccessToken(): Promise<string> {
  try {
    const accounts = msalInstance.getAllAccounts();

    // user is not authenticated
    if (!accounts.length) throw new Error('Not authenticated');
  } catch {
    return '';
  }

  const tokenRequest: SilentRequest = {
    scopes: [msalAppApiScope],
  };

  try {
    // request access token
    const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
    const { accessToken } = tokenResponse;
    if (!accessToken) throw new Error('Empty token');

    return accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      throw err;
    } else {
      throw err;
    }
  }
}

async function sendApiRequestWithAccessToken(url: string, requestData: unknown, method = 'POST') {
  let accessToken = '';
  //console.log({isUsingPCAppsSecurity: isUsingPCAppsSecurity()});
  if (isUsingPCAppsSecurity() === false)
    accessToken = await requestApiAccessToken();
  else {
    accessToken = localStorage.getItem('token');
    if (accessToken === undefined || accessToken === null || accessToken === '') {
      accessToken = sessionStorage.getItem('token');
    }
  }


  //console.log({accessToken});
  const data = await sendApiRequest(accessToken, url, requestData, method);

  return data;
}

function useMsalAutoSignIn() {
  useMsalAuthentication(InteractionType.Redirect);
  const msalContext = useMsal();

  updateMsalActiveAccount(msalContext);

}

function updateMsalActiveAccount(msalContext: IMsalContext) {
  const { instance, inProgress, accounts } = msalContext;

  // set active account
  if (inProgress === InteractionStatus.None && accounts.length > 0) {

    instance.setActiveAccount(accounts[0]);


  }
}

function msalLoginRedirect() {
  if (!loginRedirectStarted) {
    loginRedirectStarted = true;

    try {
      msalInstance.loginRedirect();
    } catch (err) {
      loginRedirectStarted = false;
      //console.error(err);
    }
  }
}

function msalLogoutRedirect() {
  try {
    const accounts = msalInstance.getAllAccounts();

    msalInstance.logoutRedirect({ account: accounts[0] });
  } catch  {
    return;
  } 
}

export {
  setMsalInstance,
  requestApiAccessToken,
  sendApiRequestWithAccessToken,
  useMsalAutoSignIn,
  updateMsalActiveAccount,
  msalLoginRedirect,
  msalLogoutRedirect,
};
