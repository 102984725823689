import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';



const actionBarStyles = css`
  margin-top: 8px;
  margin-bottom: 0px;
  height: 34px;
`;

const signInLockIconStyles = css`
background-color: white;
color: #8F784E;
`;

const footerStyles = css`
  position: fixed;
  bottom: 0;
  width: 99%;
  text-align: right; 
  padding: 0px;
  font-family: var(--app-ui-font-family);
`;

const appMUITheme = createTheme({
  typography: {
    fontFamily: 'var(--app-ui-font-family)',
    fontSize: 11,

  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: '20px',
          paddingBlock: '6px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          paddingBlock: '0px',
        },

      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          fontSize: '1.125rem',
          paddingBottom: '8px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {

        columnHeaders: {
          borderTop: '1px solid #e0e0e0',
          color: 'white',
          backgroundColor: 'navy',
        },
        sortIcon: {
          color: 'silver',
        },
        filterIcon: {
          color: 'silver',
        },
        pinnedColumnHeaders: {
          color: 'white',
          resize: 'none',
          columnGap: '1',
          backgroundColor: 'silver',
          //maxWidth: '77px',
          //minWidth: '77px',
        },
        row: {
          color: '#000',
          backgroundColor: '#F7F8F9',

        },
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
          {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },

    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
          {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {

        root: {
          paddingTop: 5,
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {

        root: {
          height: 30,
          margin: 0,
          padding: 0,
        },
        input: {
          height: 20,
          marginTop: 0,
          padding: 0,
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {

        root: {
          verticalAlign: 'text-top',
          textAlign: 'start'
        },
      },
    },
    MuiAutocomplete: {

      styleOverrides: {

        input: {
          marginTop: 0,
          paddingTop: 0,
          height: 0,
        },
        root: {
          height: 30,
        },

      }
    },

    MuiTypography: {
      styleOverrides: {


        root: {
          fontSize: 11,
        }
      }
    },
  },

});

export { actionBarStyles, appMUITheme, signInLockIconStyles, footerStyles };



