import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Project } from './types/Project';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function ProjectsChangeOrders_GetForProject(projectGuid: string): Promise<Project[]> {
  
  const url = `${apiBaseUrl}ProjectsChangeOrders_GetForProject?projectGuid=${projectGuid}`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useProjectsChangeOrders_GetForProject(projectGuid: string) {
  const [data, setData] = useState<Project[]>([]);
  
  

  const loaderState = useApiLoader<Project[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await ProjectsChangeOrders_GetForProject( projectGuid);
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [projectGuid]
    )
  );

 
  return { data, ...loaderState };
}

export { ProjectsChangeOrders_GetForProject, useProjectsChangeOrders_GetForProject };
