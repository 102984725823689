import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import type { Project } from '../../api/types/Project';

interface CtrActionDialogConfirmHostProps {
  action: string;
  host: Project;
  hideDialog: () => void;
  onExecute: (_action: string, _host: Project) => void;
}

const CtrActionDialogConfirmHost = React.memo(function CtrActionDialogConfirmHost({
  action,
  host,
  hideDialog,
  onExecute,
}: CtrActionDialogConfirmHostProps) {
  const actionText = action === 'add' ? 'Add' : action === 'delete' ? 'Delete' : action;
  const hostNameText = '';

  function handleClickConfirm() {
    // hide dialog
    hideDialog();

    onExecute(action, host);
  }

  return (
    <Dialog open={!!action} onClose={hideDialog}>
      <DialogTitle>{actionText} Timesheet</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {host?.projectGuid} {hostNameText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog}>Cancel</Button>
        <Button onClick={handleClickConfirm}>{actionText}</Button>
      </DialogActions>
    </Dialog>
  );
});

function useConfirmHostActionDialog() {
  const [confirmHostActionState, setConfirmHostActionState] = useState<{
    action: string;
    host: Project;
  }>();

  const showConfirmHostActionDialog = useCallback((action: string, host: Project) => {
    setConfirmHostActionState({ action, host });
  }, []);

  const hideConfirmHostActionDialog = useCallback(() => {
    setConfirmHostActionState(null);
  }, []);

  return { confirmHostActionState, showConfirmHostActionDialog, hideConfirmHostActionDialog };
}

export { CtrActionDialogConfirmHost as default, useConfirmHostActionDialog };
