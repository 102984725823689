import { useMemo, useState } from 'react';
import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import useApiLoader from '../lib/useApiLoader';
import type { PCAUsers } from './types/PCAUsers';


async function PCAUsers_GetForGuid(userGuid: string): Promise<PCAUsers> {
    
  const url = `${apiBaseUrlSecurity}PCAUsers_GetForGuid?userGuid=${(userGuid === null || userGuid === undefined ? 0 : userGuid)}`;
  // call API server
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function usePCAUsers_GetForGuid(userGuid: string) {
  const [data, setData] = useState<PCAUsers>();

  const loaderState = useApiLoader<PCAUsers>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await PCAUsers_GetForGuid(userGuid);
          return data;
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [userGuid]
    )
  );
 
  return { data, ...loaderState };
}

export { PCAUsers_GetForGuid, usePCAUsers_GetForGuid };
