import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import { Project } from './types/Project';
import { DataGridTypes } from 'devextreme-react/cjs/data-grid';

async function ProjectsChangeOrders_Add(projectGuid: string): Promise<any> {

  const url = `${apiBaseUrl}ProjectsChangeOrders_Add?projectGuid=${projectGuid}`;
  
  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, null );
    return data.response;
  } catch (err) {
    //console.error(err);
    return null;
  }

  
}

export { ProjectsChangeOrders_Add };