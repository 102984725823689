import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { apiBaseUrlSecurity } from '../../config/appApiConfig';

interface PasswordChangeProps {
  onPasswordChangeSubmitted: () => void;
}

function isUsingPCAppsSecurity() {
  let isPCApps = false;
  const loginType = localStorage.getItem('logintype') ?? ''; //) ? localStorage.getItem('logintype') === 'pca' ? isUsing = true : false : isUsing=false
  //console.log({loginType});

  if (loginType === 'pcapps')
    isPCApps = true;

  return isPCApps;
}

function getTokenHeader() {
  let accessToken = '';
  if (isUsingPCAppsSecurity() === true) {
    accessToken = localStorage.getItem('token');
    if (accessToken === undefined || accessToken === 'undefined' || accessToken === null || accessToken === '') {
      accessToken = sessionStorage.getItem('token');
    }
  }

  return accessToken;
}
function PasswordChange({ onPasswordChangeSubmitted }: PasswordChangeProps) {

  const [isRequestSubmitted] = useState(false);
  const [passwordCheck1, setPasswordCheck1] = useState('');
  const [passwordCheck2, setPasswordCheck2] = useState('');
  
  function handleSubmit(event) {

    event.preventDefault();


    //`${apiBaseUrl}Timesheets_GetForBrowseWeeklyView?UserName=${user.userName}&DateStart=${dateStart}`
    if (isRequestSubmitted === false) {
      //console.log({emailAddress});
      if ((passwordCheck1 === passwordCheck2) && (passwordCheck2.trim() !== '')) {
        const requestOptions_PasswordChange = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${getTokenHeader()}`,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': 'application/json'
          },
          body: new URLSearchParams({
            'newPassword': passwordCheck2
          })
        };

        try {
          const url = `${apiBaseUrlSecurity}PasswordChangeOnMustChange`;
          //console.log({url});
          fetch(url, requestOptions_PasswordChange)
            .then(response => response.json())
            .then(data => {
              if (data.code !== null && data.code !== 'null' && data.code === '0') {
                window.location.reload();
              }

            });
        } catch {
          /* ignore error, continue */
          //console.log('Change password failed');
        }
      }
    }

  }

  return (
    <div>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h1' variant='h5'>
            Change Password
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={event => setPasswordCheck1(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='passwordnew'
              label='New Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <TextField
              onChange={event => setPasswordCheck2(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='passwordnewconfirm'
              label='Confirm New Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            {(
              <>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Password
                </Button>
              </>
            )}

          </Box>
        </Box>
      </Container>
    </div>

  );

}

export default PasswordChange;