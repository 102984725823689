import React,  { useState }  from 'react';
import { css } from '@emotion/react';
import type { } from '@mui/x-data-grid/themeAugmentation';

import { Button } from 'devextreme-react/button';
import { CheckBox, CheckBoxTypes } from 'devextreme-react/check-box';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';

import { Drawer } from '@mui/material';

import ControlGridRoles from './CtrGridRoles';
// import ControlDropdownUsersAutoComplete from './CtrDropdownUsersAutoComplete';
// import ControlDropdownClientsProjectsSalesOrders from './CtrDropdownClientsProjectsSalesOrders';
// import ControlDropdownProjectsServiceItems from './CtrDropdownProjectsServiceItems';

// import { usePCAUsers_Get } from '../../api/PCAUsers_Get';
// import { useClientsProjectsSalesOrders_GetAll } from '../../api/ClientsProjectsSalesOrders_GetAll';
// import { useProjectsServiceItems_GetForSalesOrderGuid } from '../../api/ProjectsServiceItems_GetForSalesOrderGuid';
import type { PCAUsers } from '../../api/types/PCAUsers';

import { PCAUsers_AddEdit } from '../../api/PCAUsers_AddEdit';
import { PCAUsers_Delete } from '../../api/PCAUsers_Delete';

import ConfirmDialog from '../shared/ConfirmDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { canAccessPage } from '/lib/canAccessPage';
import { useUsersOrgsRoles_GetForUserGuid } from '../../api/UsersOrgsRoles_GetForUserGuid';
import PasswordChangeWithCurrent from './PasswordChangeWithCurrent';
import { PageCanAccessWebUserSecurity } from '/lib/appContextStore';

const bodyStyles = css`
  line-height: 46px;
`;

const closeButtonStyle = css`
  background-color: black;
  margin-left: 6px;
`;

const cancelButtonStyle = css`
  background-color: darkred;
  margin-left: 6px;
`;

const closeButtonDialogStyle = css`
  background-color: black;
  width: 10px;
  margin-left: 18px;
`;

const cancelButtonDialogStyle = css`
  background-color: darkred;
  width: 10px;
  margin-left: 18px;
`;

const deleteButtonStyle = css`
  background-color: #400408;
  margin-left: 33px;
`;

const childListGridStyles = css`
  flex: 0;
  margin-top: 8px;
`;


interface UserAddEditProps {
  UserGuid: string,
  isOpenViaMyAccount: boolean,
  isDrawerOpen: boolean,
  //isNeedToSaveChanges?: boolean,
  userData: PCAUsers,

  handleSaveStateOnDataChanges?: (isDataChanged: boolean) => void;
  onRefreshParentComponent?: (isRefetchData: boolean) => void;
  toggleDrawer: () => void;
}




const UserAddEdit = React.memo(function UserAddEdit({
  UserGuid,
  isOpenViaMyAccount = false,
  isDrawerOpen,

  //isNeedToSaveChanges = false,
  userData,
  handleSaveStateOnDataChanges,
  onRefreshParentComponent,
  toggleDrawer
}: UserAddEditProps) {

  const [isNeedToSaveChanges, setIsNeedToSaveChanges] = useState(false)
  function dataChanged() {
    setIsNeedToSaveChanges(true);
    handleSaveStateOnDataChanges(true);
  }
  const handleOnDataChanges = (event) => {
    setIsNeedToSaveChanges(true)
    handleSaveStateOnDataChanges(true)
  }

  // Start Loading Data =================================================================================
  const [openViaMyAccount] = useState(isOpenViaMyAccount)

  const [firstName, setFirstName] = useState(userData?.firstName ?? '');
  const [middleName, setMiddleName] = useState(userData?.middleName ?? '');
  const [lastName, setLastName] = useState(userData?.lastName ?? '');
  const [emailAddress, setEmailAddress] = useState(userData?.emailAddress ?? '');
  const [employeeId, setEmployeeId] = useState(userData?.employeeId ?? '');
  const [employeeTitle, setEmployeeTitle] = useState(userData?.employeeTitle ?? '');
  const [officeLocation, setOfficeLocation] = useState(userData?.officeLocation ?? '');
  const [phoneHome, setPhoneHome] = useState(userData?.phoneHome ?? '');
  const [phoneWork, setPhoneWork] = useState(userData?.phoneWork ?? '');
  const [phoneMobile, setPhoneMobile] = useState(userData?.phoneMobile ?? '');

  const [isEnabled, setIsEnabled] = useState(userData?.isEnabled ?? null);
  const [isLocked, setIsLocked] = useState(userData?.isLocked ?? null);
  const [userName, setUserName] = useState(userData?.userName ?? '');
    
  const [showPasswordChange, setShowPasswordChange] = useState(false);

  // load roles list
  const {
    data: roles,
    isLoading: loadingRoles,
    refetch: refetchRoles,
  } = useUsersOrgsRoles_GetForUserGuid((userData !== null && userData !== undefined ? (userData.userGuid === undefined ? '' : userData.userGuid) : ''));


  // End Loading Data =================================================================================
 
  // const [activePrivateNotes, setActivePrivateNotes ] = useState('');
  // const handleOnChangeActivePrivateNotes = (event) => {
  //   if (UserData !== null && UserData !== undefined) {
  //     handleOnDataChanges(event);
  //     setActivePrivateNotes(event.target.value);
  //     UserData.notes  = event.target.value;
  //   }
  // }

  const saveUser = async (isAddAnother: boolean) => {
    if (userData.userGuid === null || userData.userGuid === undefined) {
      alert('Enter Username');
      return;
    }

    await PCAUsers_AddEdit(userData);
    // console.log('roles.length: ', roles.length);
    // roles.forEach((role) => {
    //   console.log('role.roleName: ', role.roleName);
    //   console.log('role.userGuid: ', role.userGuid);
    //   console.log('role.roleGuid: ', role.roleGuid);
    //   console.log('role.isMember: ', role.isMember);
    //   UsersOrgsRoles_Assign(role);
    // });


    setIsNeedToSaveChanges(false);
    handleSaveStateOnDataChanges(false);
    onRefreshParentComponent(true);

    if (isAddAnother === false) {
      toggleDrawer()
    } else {
      userData.userGuid = '';



      //setActiveProjectServiceItemId(0);
      setIsNeedToSaveChanges(false);
      onRefreshParentComponent(true);
    }
  }

  const deleteUser = async () => {

    await PCAUsers_Delete(userData);
    setIsNeedToSaveChanges(false);
    handleSaveStateOnDataChanges(false);
    onRefreshParentComponent(true);

    toggleDrawer();
  }

  const onCloseChangePassword = async () => {
    setShowPasswordChange(false);
  }

  function cancelChanges() {
    setIsNeedToSaveChanges(false);
    handleSaveStateOnDataChanges(false);
  }


  const handleOnCloseButtonClick = (event) => {
    if (isNeedToSaveChanges) {
      //if (QuoteId !== 0) {
      cancelChanges();
      //TODO: Complete undo/cancel of changes
      //}
    } else {
      toggleDrawer();
    }
  }

  const [confirmOpen, setConfirmOpen] = useState(false)

  return (
    <>

      <Drawer variant='persistent' anchor='right' open={isDrawerOpen} ModalProps={{ keepMounted: true }} style={{ width: '390px'}} >
        <div style={{padding: '20px', paddingTop: '0px'}}>

          <h1>
            {
              openViaMyAccount ? 'My Account' : (UserGuid === '' ? 'Add New User' : `Edit User: ${userData.userName}`)
            }

            <Button id={"dxCloseButtonDialogTopCorner"} width={40} css={isNeedToSaveChanges ? cancelButtonDialogStyle : closeButtonDialogStyle} onClick={handleOnCloseButtonClick} > X </Button>
          </h1>
  
          <table >
            <tr>
              <td style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              <TextBox id='firstName' label='First Name'
                defaultValue={firstName}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setFirstName(e.value);
                  userData.firstName = e.value;
                  dataChanged();
                }}
              />
              </td>
              <td>
              <TextBox id='middleName' label='Middle Name'
                defaultValue={middleName}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setMiddleName(e.value);
                  userData.middleName = e.value;
                  dataChanged();
                }}
              />
              </td>
              <td>
              <TextBox id='lastName' label='Last Name'
                defaultValue={lastName}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setLastName(e.value);
                  userData.lastName = e.value;
                  dataChanged();
                }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3} style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              <TextBox id='employeeId' label='Employee ID'
                defaultValue={employeeId}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setEmployeeId(e.value);
                  userData.employeeId = e.value;
                  dataChanged();
                }}
              />
              <TextBox id='employeeTitle' label='Title/Position'
                defaultValue={employeeTitle}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setEmployeeTitle(e.value);
                  userData.employeeTitle = e.value;
                  dataChanged();
                }}
              />
              {/* <TextField id='defaultLanguageId'  label='Language'
                                value={defaultLanguageId}
                                style={{ width: 120, paddingLeft: 5 }}
                                InputLabelProps={{ shrink: true, style: { color: 'blue', paddingLeft: 5 }}}
                                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => { 
                                  setDefaultLanguageId(e.value); 
                                  userData.defaultLanguageId = parseInt(e.value); 
                                  dataChanged(); 
                                }}
                            /> */}
            </td>
          </tr>
          <tr>
            <td colSpan={3} style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              <TextBox id='officeLocation' label='Office'
                defaultValue={officeLocation}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setOfficeLocation(e.value);
                  userData.employeeTitle = e.value;
                  dataChanged();
                }}
              />
              {/* <TextField id='defaultLanguageId'  label='Language'
                                value={defaultLanguageId}
                                style={{ width: 120, paddingLeft: 5 }}
                                InputLabelProps={{ shrink: true, style: { color: 'blue', paddingLeft: 5 }}}
                                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => { 
                                  setDefaultLanguageId(e.value); 
                                  userData.defaultLanguageId = parseInt(e.value); 
                                  dataChanged(); 
                                }}
                            /> */}
            </td>
          </tr>
          <tr>
            <td colSpan={3} style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              <TextBox id='emailAddress' label='Email Address'
                defaultValue={emailAddress}
                style={{ width: 245, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setEmailAddress(e.value);
                  userData.emailAddress = e.value;
                  dataChanged();
                }}
              />
              {/* <TextField id='emailBodyFormatPreference'  label='Format Preference'
                                value={emailBodyFormatPreference}
                                style={{ width: 120, paddingLeft: 5 }}
                                InputLabelProps={{ shrink: true, style: { color: 'blue', paddingLeft: 5 }}}
                                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => { 
                                  setEmailBodyFormatPreference(e.value); 
                                  userData.emailBodyFormatPreference = e.value; 
                                  dataChanged(); 
                                }}
                            /> */}
            </td>
          </tr>
          <tr>
            <td >
              <TextBox id='phoneWork' label='Phone Office'
                defaultValue={phoneWork}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setPhoneWork(e.value);
                  userData.phoneWork = e.value;
                  dataChanged();
                }}
              />
              </td>
              <td>
              <TextBox id='phoneHome' label='Phone Home'
                defaultValue={phoneHome}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setPhoneHome(e.value);
                  userData.phoneHome = e.value;
                  dataChanged();
                }}
              />
              </td>
              <td>
              <TextBox id='phoneMobile' label='Phone Mobile'
                defaultValue={phoneMobile}
                style={{ width: 120, paddingLeft: 5 }}
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setPhoneMobile(e.value);
                  userData.phoneMobile = e.value;
                  dataChanged();
                }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              <TextBox id='userName' label='Username'
                defaultValue={userName}
                                
                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => {
                  setUserName(e.value);
                  userData.userName = e.value;
                  dataChanged();
                }}
              />
              </td>
              <td>
              {openViaMyAccount ? '' :
                <>
                  
                  <CheckBox
                    text='Enabled?'
                    defaultValue={isEnabled}
                  
                    onValueChanged={(e: CheckBoxTypes.ValueChangedEvent) => { setIsEnabled(e.value); userData.isEnabled = e.value; dataChanged(); }}
                      style={{ marginLeft: 8, paddingLeft: 8, paddingRight: 4 }} />
              
                  <CheckBox
                    text='Locked?'
                    defaultValue={isLocked}
                    onValueChanged={(e: CheckBoxTypes.ValueChangedEvent) => { setIsLocked(e.value); userData.isLocked = e.value; dataChanged(); }}
                    style={{ marginLeft: 0, paddingLeft: 0, paddingRight: 4 }} />

                </>
              }
            </td>
          </tr>
          <tr>
            <td colSpan={3} style={{ marginTop: 0, padding: 0, paddingTop: 8, border: 0 }}>
              {/* <TextField id='password'  label='Password'
                                value={password}
                                type={passwordType ? 'password' : 'text'}
                                style={{ width: 245, paddingLeft: 5 }}
                                InputLabelProps={{ shrink: true, style: { color: 'blue', paddingLeft: 5 }}}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton onClick={handleAutoPasswordButtonClick}>
                                        <RestartAltOutlinedIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                onValueChanged={(e: TextBoxTypes.ValueChangedEvent) => { setPassword(e.value); userData.password = e.value; dataChanged(); }}
                            /> */}
            </td>
          </tr>
          
          <tr>
            <td>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
        </table>
        
        
        <div>
          {showPasswordChange && <PasswordChangeWithCurrent userName={userData == null ? '' : userData.userName} onPasswordChangeSubmitted={onCloseChangePassword} />}
        </div>
        <table>
          <tr>
            <td>
              {openViaMyAccount ? '' : <Button id="dxSaveAddButton" text="contained" type="default" onClick={() => saveUser(true)} > Save & Add New</Button>}

              <Button id="dxSaveButton" text="contained" type="default" onClick={() => saveUser(false)} style={{ marginLeft: '3px' }}> Save </Button>
              <Button id="dxChangePasswordButton" text="contained" type="default" onClick={() => setShowPasswordChange(true)} style={{ marginLeft: '3px' }}> Change Password </Button>
              <Button id="dxCloseButton" text="contained" type="default" css={isNeedToSaveChanges ? cancelButtonStyle : closeButtonStyle} onClick={handleOnCloseButtonClick} > {isNeedToSaveChanges ? 'Cancel' : 'Close'} </Button>
              {UserGuid === '' || openViaMyAccount === true ? '' :
                <Button id={"dxDeleteButton"} text="contained" onClick={() => setConfirmOpen(true)} > DELETE </Button>
              }

            </td>
          </tr>
        </table>
        <ConfirmDialog
          title='Delete User?'
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteUser}
        >
          Are you sure you want to delete this User?
        </ConfirmDialog>
        {isOpenViaMyAccount ? '' :
        (
          <ControlGridRoles
            css={childListGridStyles}
            dataList={roles}
          />
        )
        }
        
        </div>
      </Drawer>
    </>
  );

});

export default UserAddEdit;
//export default canAccessPage(UserAddEdit, 'security');