import { useState } from 'react';
import { styles } from '../shared/Styles';

import { Button } from 'devextreme-react/button';
import { CheckBox, CheckBoxTypes } from 'devextreme-react/check-box';
import Box, { Item } from 'devextreme-react/box';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { apiBaseUrlSecurity } from '../../config/appApiConfig';
import PasswordChange from '../security/PasswordChange';
import AppFooter from '../shared/AppFooter';
import { footerStyles, signInLockIconStyles } from '../shared/AppStylesTheme';



interface LoginPagePCAppsProps {
  onForgotPassword: () => void;
}

function handlePCASecurityPasswordChangeSubmitted() {
  // sign in

}


function LoginPagePCApps({ onForgotPassword }: LoginPagePCAppsProps) {

  const [token, setToken] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [signinStatus, setSignInStatus] = useState('');
  const [mustChangePassword, setMustChangePassword] = useState(false);

  function handleSubmit(event) {

    //event.preventDefault();

    if (token === null || token === 'null') {
      //Do Login
      const requestOptions_Login = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      };

      
      fetch(apiBaseUrlSecurity + 'SignIn', requestOptions_Login)
        .then(response => response.json())
        .then(data => {

          if ((data.code !== null) && (data.code === '1')) {
            if (data.response.token !== null && data.response.token !== 'null') {
              setToken(data.response.token);
              //set JWT token to local
              if (rememberMe === true)
                localStorage.setItem('token', data.response.token);
              else
                sessionStorage.setItem('token', data.response.token);
              localStorage.setItem('logintype', 'pcapps');
            }

            window.location.reload();
          }
          else {
            if ((data.code === '2')) {
              //console.log({ token: data.response.token })
              if (data.response.token !== null && data.response.token !== 'null' && data.response.token !== 'undefined') {

                setToken(data.response.token);
                //set JWT token to local
                if (rememberMe === true)
                  localStorage.setItem('token', data.response.token);
                else
                  sessionStorage.setItem('token', data.response.token);
                localStorage.setItem('logintype', 'pcapps');
                setMustChangePassword(true);
              }

            } else {
              setSignInStatus(data.message);
            }
          }
        });
    }
  }

  return (
    mustChangePassword ?
      <PasswordChange onPasswordChangeSubmitted={handlePCASecurityPasswordChangeSubmitted} />
      :
      <div css={styles} >
        

  
          <div  style={{width: '400px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
            <Box direction="row" width="330">
              <Item ratio={1} baseSize={300} >
                <div style={{alignContent: 'center'}}>
                  <img src='images/logowelcome.png' alt='PCApps \ PonomaSwapMeet' style={{marginBottom: '40px', width: '300px'}} />
                </div>

                <div style={{textAlign: 'center', width: '100%', fontSize: '28px', fontWeight:'600', borderSpacing: '5px'}}>
                  <LockOutlinedIcon css={signInLockIconStyles} />  Sign in
                
              
                <TextBox
                  onValueChanged={(data: TextBoxTypes.ValueChangedEvent) => {setUsername(data.value); } }
                  style={{marginBottom: '10px'}}
                  id='email'
                  label='Email Address or Username'
                  name='email'
                  labelMode='floating'
                />
                <TextBox
                  onValueChanged={(data: TextBoxTypes.ValueChangedEvent) => {setPassword(data.value); }}
                  style={{marginBottom: '10px'}}
                  mode='password'
                  name='password'
                  label='Password'
                  id='password'
                  labelMode='floating'
                />
                </div>
                <CheckBox text='Remember me' defaultValue={rememberMe}  onValueChanged={(e: CheckBoxTypes.ValueChangedEvent) => {setRememberMe(e.value); } }  />
                <br/>
                              
                <span style={{ fontSize: 14, color: 'red', padding: 0, margin: 0, height: '20px' }}>{signinStatus}</span>
                
                <br/>
                <Button
                  id={"dxButtonSignIn"}
                  onClick={handleSubmit}

                >
                  Sign In
                </Button>

              
              
                <Button
                  id={"dxButtonForgotPassword"}
                  onClick={onForgotPassword}
                  text='Forgot password?'
                />
              </Item>
            </Box>
            
          </div>
        
        <div css={footerStyles}>
          <AppFooter></AppFooter>
        </div>
      </div>
  );
}

export default LoginPagePCApps;