import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Roles } from './types/Roles';

async function UsersOrgsRoles_Assign(ts: Roles): Promise<string> {

  const url = `${apiBaseUrlSecurity}UsersOrgsRoles_Assign`;
  
  
  // const tsDate = new Date(ts.quoteEntryDate)

  // ts.quoteEntryDate = new Date(tsDate.getFullYear(), tsDate.getMonth(), tsDate.getDate(), 0, 0, 0, 0)

  if (ts.roleGuid === '') {
    ts.roleGuid = '{00000000-0000-0000-0000-000000000000}';
  }
  

  const requestData = {
    userGuid: ts.userGuid,
    roleGuid: ts.roleGuid,
    isMember: ts.isMember
  };

  //function to calculateMaxNumber
  

  // call API server
  try {
    const data = await sendApiRequestWithAccessToken(url, requestData);
    
    return data;
  } catch (err) {
    return null;
  }

  
  
}

export { UsersOrgsRoles_Assign };
