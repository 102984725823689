import { Button } from 'devextreme-react/button';

function SubmitForReviewButton({ disabled = false, onClick, visible }) {
  return (
    <Button
      width={150}
      id={'dxSubmitForReviewButton'}
      text="contained"
      type="default"
      disabled={disabled}
      onClick={onClick}
      visible={visible}>
      Submit for Review
    </Button>
  );
}

export default SubmitForReviewButton;
