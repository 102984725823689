import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import App from './pages/shared/App';

import Projects from './pages/projects/ProjectBrowse';
import Dashboard from './pages/dashboard/DashboardBrowse';
import Users from './pages/security/UserBrowse';
import JobTypes from './pages/utilities/setupJobTypes/JobTypesBrowse';
import SalespersonJobCommissionRates from './pages/utilities/setupSalesperson/SalespersonJobCommissionRatesBrowse';
import InsufficientPrivileges from './pages/shared/InsufficientPrivileges';

const routes: RouteObject[] = [
  {

    path: '/',
    element: <App />,
    children: [
      { index: true, element: <Navigate to='home' /> },
      { path: 'home', element: <Projects /> },
      { path: 'projects', element: <Projects /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'jobtypes', element: <JobTypes /> },
      { path: 'salesRates', element: <SalespersonJobCommissionRates /> },
      
      { path: 'users', element: <Users /> },
      { path: 'notallowed', element: <InsufficientPrivileges /> },

    ],
  },
  { path: '*', element: <h1>Page not found</h1> },
];

export { routes };

