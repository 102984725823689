import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { apiBaseUrlSecurity } from '../../config/appApiConfig';

interface PasswordChangeWithCurrentProps {
  userName: string;
  onPasswordChangeSubmitted: () => void;
}

function isUsingPCAppsSecurity() {
  let isPCApps = false;
  const loginType = localStorage.getItem('logintype') ?? ''; //) ? localStorage.getItem('logintype') === 'pca' ? isUsing = true : false : isUsing=false
  //console.log({loginType});

  if (loginType === 'pcapps')
    isPCApps = true;

  return isPCApps;
}

function getTokenHeader() {
  let accessToken = '';
  if (isUsingPCAppsSecurity() === true) {
    accessToken = localStorage.getItem('token');
    if (accessToken === undefined || accessToken === 'undefined' || accessToken === null || accessToken === '') {
      accessToken = sessionStorage.getItem('token');
    }
  }
  return accessToken;
}
function PasswordChangeWithCurrent({ userName, onPasswordChangeSubmitted }: PasswordChangeWithCurrentProps) {

  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordCheck1, setPasswordCheck1] = useState('');
  const [passwordCheck2, setPasswordCheck2] = useState('');

  const [changePasswordStatus, setChangePasswordStatus] = useState('');

  
  function handleSubmit(event) {

    event.preventDefault();

   
    if (isRequestSubmitted === false) {
      
      if ((passwordCheck1 === passwordCheck2) && (passwordCheck2.trim() !== '')) {
        const requestOptions_PasswordChange = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getTokenHeader()}`,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            Accept: 'application/json'
          },
          body: new URLSearchParams({
            userName: userName,
            oldPassword: passwordCurrent,
            newPassword: passwordCheck2
          })
        };

        try {
          const url = `${apiBaseUrlSecurity}PasswordChange`;
          
          fetch(url, requestOptions_PasswordChange)
            .then(response => response.json())
            .then(data => {
              if (data.code === null || data.code === 'null' || data.code !== '0') {
                //console.log('Change password failed');
                setChangePasswordStatus('Change password failed');
              } else {
                //console.log('Change password submitted');
                setChangePasswordStatus('Change password submitted');
                // window.location.reload();
                onPasswordChangeSubmitted();
                setIsRequestSubmitted(true);
              }

            });
        } catch {
          //console.log('Change password failed');
          setChangePasswordStatus('Change password failed');
        }
      }
      else {
        setChangePasswordStatus('Please re-confirm the new password.');
      }
    }

  }

  return (
    <div>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            marginBottom: 4,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid grey',
            borderRadius: '8px'
          }}
        >
          <Typography component='h1' variant='h5'>
            Change Password
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={event => setPasswordCurrent(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='passwordcurrent'
              label='Current Password'
              type='password'
              id='passwordcurrent'
              autoComplete='current-password'
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              onChange={event => setPasswordCheck1(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='passwordnew'
              label='New Password'
              type='password'
              id='passwordnew'
              autoComplete='current-password'
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              onChange={event => setPasswordCheck2(event.target.value)}
              margin='normal'
              required
              fullWidth
              name='passwordnewconfirm'
              label='Confirm New Password'
              type='password'
              id='passwordnewconfirm'
              InputLabelProps={{ shrink: true }}
            />
            <Button onClick={handleSubmit}>Change Password</Button>
            <Button onClick={onPasswordChangeSubmitted}>Cancel</Button>

            <Box style={{ height: 20, padding: 0, margin: 0 }}>
              <span style={{ fontSize: 14, color: 'red', padding: 0, margin: 0 }}>{changePasswordStatus}</span>
            </Box>
            {/*             
            {(
              <>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
              Change Password
              </Button>
              </>
          )} */}

          </Box>
        </Box>
      </Container>
    </div>

  );

}

export default PasswordChangeWithCurrent;