import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Project } from './types/Project';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function Projects_GetForBrowseListView(salesPersonUserName: string, projectStatusList: number[], startYear: number, stopYear: number): Promise<Project[]> {
  
  const projectStatusListString: string = projectStatusList.join(',');
  const url = `${apiBaseUrl}Projects_GetForBrowseListView?salesPersonUserName=${salesPersonUserName}&projectStatusListString=${projectStatusListString}&startYear=${startYear}&stopYear=${stopYear}`;
  const requestData = { };
  const data = await sendApiRequestWithAccessToken(url, requestData, 'GET');
  
  return data;
}

function useProjects_GetForBrowseListView(salesPersonUserName: string, projectStatusList: number[], startYear: number, stopYear: number) {
  const [data, setData] = useState<Project[]>([]);
  
  
  const loaderState = useApiLoader<Project[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await Projects_GetForBrowseListView( salesPersonUserName, projectStatusList, startYear, stopYear );
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [salesPersonUserName, projectStatusList, startYear, stopYear]
    )
  );

 
  return { data, ...loaderState };
}

export { Projects_GetForBrowseListView, useProjects_GetForBrowseListView };
