//const apiBaseUrl = 'https://localhost:7237/Timesheet/';

const isEnvProduction = process.env.NODE_ENV === 'production';
let apiBaseUrl = 'https://localhost:7106/api/PowersLA/';
let apiBaseUrlSecurity = 'https://localhost:7106/api/Account/';

if (isEnvProduction) {
  if (location.host.toLowerCase().includes('powersla-beta')) {
    apiBaseUrl = 'https://powersla-beta-api.pcapps.com/api/PowersLA/';
    apiBaseUrlSecurity = 'https://powersla-beta-api.pcapps.com/api/Account/';
  }
  else {
    apiBaseUrl = 'https://powersla-api.pcapps.com/api/PowersLA/';
    apiBaseUrlSecurity = 'https://powersla-api.pcapps.com/api/Account/';
  }
}

//apiBaseUrl = 'https://powersla-beta-api.pcapps.com/api/PowersLA/';
//apiBaseUrlSecurity = 'https://powersla-beta-api.pcapps.com/api/Account/';

//let isUsePCAppsSecurity = true;
export { apiBaseUrl, apiBaseUrlSecurity };
