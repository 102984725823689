import { useMemo, useState } from 'react';
import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import useApiLoader from '../lib/useApiLoader';
import type { PCAUsers } from './types/PCAUsers';


async function PCAUsers_GetForSessionUser(): Promise<PCAUsers> {

  const url = `${apiBaseUrlSecurity}PCAUsers_GetForSessionUser`;
  // call API server
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function usePCAUsers_GetForSessionUser() {
  const [data, setData] = useState<PCAUsers>();

  const loaderState = useApiLoader<PCAUsers>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await PCAUsers_GetForSessionUser();
          return data;
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );
 
  return { data, ...loaderState };
}

export { PCAUsers_GetForSessionUser, usePCAUsers_GetForSessionUser };
