import { createContext, useContext, useReducer } from 'react';
import { produce } from 'immer';
import type { PCAUsers } from '../api/types/PCAUsers';

interface AppContextStore {
  activeUserId: string;
  activeUserIdWithShowAll: string;
  usersList: PCAUsers[];
  usersListWithShowAll: PCAUsers[];
  isPagePermissionCheckCompleted: boolean;
  canAccessProjects: boolean;
  canAccessProjectsAdminFeatures: boolean;
  canAccessDashboard: boolean;
  canAccessUtilities: boolean;
  canAccessWebUserSecurity: boolean;

}

const initialState: AppContextStore = {
  activeUserId: '0',
  activeUserIdWithShowAll: '0',
  usersList: [],
  usersListWithShowAll: [],

  isPagePermissionCheckCompleted: false,
  canAccessProjects: false,
  canAccessProjectsAdminFeatures: false,
  canAccessDashboard: false,
  canAccessUtilities: false,
  canAccessWebUserSecurity: false,

};

type AppContextAction =
  | { type: 'Users/updateUsersList'; payload: PCAUsers[] }
  | { type: 'Users/updateUsersListWithShowAll'; payload: PCAUsers[] }
  | { type: 'Users/updateActiveUserId'; payload: string }
  | { type: 'Users/updateActiveUserIdWithShowAll'; payload: string }
  | { type: 'Pages/isPagePermissionCheckCompleted'; payload: boolean }
  | { type: 'Pages/canAccessProjects'; payload: boolean }
  | { type: 'Pages/canAccessProjectsAdminFeatures'; payload: boolean }
  | { type: 'Pages/canAccessDashboard'; payload: boolean }
  | { type: 'Pages/canAccessUtilities'; payload: boolean }
  | { type: 'Pages/canAccessWebUserSecurity'; payload: boolean }

  
  ;

type AppContextDispatch = React.Dispatch<AppContextAction>;

const StoreContext = createContext<AppContextStore>(null);
const StoreDispatchContext = createContext<AppContextDispatch>(null);
const userIdMap = new Map<string, PCAUsers>();
//const isEnvDevelopment = process.env.NODE_ENV === 'development';

function AppContextStoreProvider({ children }) {
  const [store, dispatch] = useReducer(produce(appContextStoreReducer), initialState);

  return (
    <StoreDispatchContext.Provider value={dispatch}>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </StoreDispatchContext.Provider>
  );
}

function appContextStoreReducer(draft: AppContextStore, action: AppContextAction) {
  //if (isEnvDevelopment) console.log('AppContextStore action', action);

  switch (action.type) {
    case 'Users/updateUsersList':
      // use existing data
      if (draft.usersList.length) break;

      draft.usersList = action.payload;
      break;
    case 'Users/updateUsersListWithShowAll':
      // use existing data
      if (draft.usersListWithShowAll.length) break;

      draft.usersListWithShowAll = action.payload;
      break;
    case 'Users/updateActiveUserId':
      draft.activeUserId = action.payload;
      break;
    case 'Users/updateActiveUserIdWithShowAll':
      draft.activeUserIdWithShowAll = action.payload;
      break;
    case 'Pages/isPagePermissionCheckCompleted':
      draft.isPagePermissionCheckCompleted = action.payload;
      break;

    case 'Pages/canAccessProjects':
      draft.canAccessProjects = action.payload;
      break;
    case 'Pages/canAccessProjectsAdminFeatures':
      draft.canAccessProjectsAdminFeatures = action.payload;
      break;
    case 'Pages/canAccessDashboard':
        draft.canAccessDashboard = action.payload;
        break;
    case 'Pages/canAccessUtilities':
      draft.canAccessUtilities = action.payload;
      break;
    case 'Pages/canAccessWebUserSecurity':
      draft.canAccessWebUserSecurity = action.payload;
      break;
  }
}

function useAppContextStore() {
  return useContext(StoreContext);
}

function useAppContextDispatch() {
  return useContext(StoreDispatchContext);
}

function findUser(accountList: PCAUsers[], userGuid: string) {
  const account = accountList.find(e => (e.userGuid) === userGuid);
  return account;
}

function useActiveUser() {
  const { activeUserId, usersList } = useAppContextStore();

  // look up account by account ID
  const key = activeUserId ?? '0';
  const mapValue = userIdMap.get(key);
  if (mapValue) return mapValue;

  const account = findUser(usersList, key);
  if (account) {
    userIdMap.set(key, account);
    return account;
  }

  if (key === '0') {
    if (usersList.length > 0) {
      const defUser = usersList.filter(f => f.isDefault === true)

      if (defUser.length > 0) {
        const keyAlt = defUser[0].userGuid ?? '0';

        const mapValueAlt = userIdMap.get(keyAlt);
        if (mapValueAlt) return mapValueAlt;

        const accountAlt = findUser(usersList, keyAlt);
        if (accountAlt) {
          userIdMap.set(keyAlt, accountAlt);
          return accountAlt;
        }
      }

    }
  }
}

function useActiveUserWithShowAll() {
  const { activeUserIdWithShowAll, usersListWithShowAll } = useAppContextStore();

  // look up account by account ID
  const key = activeUserIdWithShowAll ?? '0';
  const mapValue = userIdMap.get(key);
  if (mapValue) return mapValue;

  const account = findUser(usersListWithShowAll, key);
  if (account) {
    userIdMap.set(key, account);
    return account;
  }

  if (key === '0') {
    if (usersListWithShowAll.length > 0) {
      const defUser = usersListWithShowAll.filter(f => f.isDefault === true)

      if (defUser.length > 0) {
        const keyAlt = defUser[0].userGuid ?? '0';

        const mapValueAlt = userIdMap.get(keyAlt);
        if (mapValueAlt) return mapValueAlt;

        const accountAlt = findUser(usersListWithShowAll, keyAlt);
        if (accountAlt) {
          userIdMap.set(keyAlt, accountAlt);
          return accountAlt;
        }
      }

    }
  }
}


function PageCanAccessProjects(): boolean {
  const { canAccessProjects } = useAppContextStore();
  return canAccessProjects;
}

function PageCanAccessProjectsAdminFeatures(): boolean {
  const { canAccessProjectsAdminFeatures } = useAppContextStore();
  return canAccessProjectsAdminFeatures;
}

function PageCanAccessDashboard(): boolean {
  const { canAccessDashboard } = useAppContextStore();
  return canAccessDashboard;
}

function PageCanAccessUtilities(): boolean {
  const { canAccessUtilities } = useAppContextStore();
  return canAccessUtilities;
}

function PageCanAccessWebUserSecurity(): boolean {
  const { canAccessWebUserSecurity } = useAppContextStore();
  return canAccessWebUserSecurity;
}

function PageIsPagePermissionCheckCompleted(): boolean {

  const { isPagePermissionCheckCompleted } = useAppContextStore();

  return isPagePermissionCheckCompleted;
}


export {
  AppContextStoreProvider,
  useAppContextStore,
  useAppContextDispatch,
  useActiveUser,
  useActiveUserWithShowAll,
  PageIsPagePermissionCheckCompleted,
  PageCanAccessProjects,
  PageCanAccessProjectsAdminFeatures,
  PageCanAccessDashboard,
  PageCanAccessUtilities,
  PageCanAccessWebUserSecurity,
  type AppContextDispatch,
};
