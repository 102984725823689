import { LicenseInfo } from '@mui/x-data-grid-pro';
//import { isUsePCAppsSecurity } from '../../config/appApiConfig';
LicenseInfo.setLicenseKey(
  '67a2331c90612927f35008e8e3b2d2c5Tz02Mzk2MCxFPTE3MTI3NTM4MzM1MTQsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);
import config from 'devextreme/core/config'; 
import { licenseKey } from '../../devextreme-license'; 
 
config({ licenseKey });   

import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import {  useMsal, useIsAuthenticated } from '@azure/msal-react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import LoginPageAzureADMSAL from '../security/LoginPageAzureADMSAL';
import LoginPagePCApps from '../security/LoginPagePCApps';
import ForgotPassword from '../security/ForgotPassword';
import { msalLoginRedirect, updateMsalActiveAccount } from '/lib/msal';
import { useState } from 'react';
import { isJwtExpired } from 'jwt-check-expiration';
import { CheckAndAdd_MSALUserAccount } from '../../api/CheckAndAdd_MSALUserAccount';
import { styles } from './Styles';

import '../shared/dx.material.powersLA_materialui.css';
 
// const styles = css`
//   display: grid;
//   grid-template-rows: auto 1fr auto;
//   margin-inline: 16px;
//   min-height: 100vh;
//   font-family: var(--app-ui-font-family);
// `;


function App() {
  const [isUsePCAppsSecurity, setIsUsePCAppsSecurity] = useState(true);
  const [isShowForgotPassword, setIsShowForgotPassword] = useState(false);
  const msalContext = useMsal();

  updateMsalActiveAccount(msalContext);

  function checkPCAppsTokenExpired() {
    let token = '';
    token = localStorage.getItem('token') ?? '';

    if (token === '') {
      token = sessionStorage.getItem('token') ?? '';
    }

    //console.log({token})

    if ((token !== '') && (token !== undefined) && (token !== 'undefined')) {
      //console.log('isExpired is');
      if (isJwtExpired(token) === true) {
        try { localStorage.removeItem('token') } catch { /* ignore error, continue */ }
        try { sessionStorage.removeItem('token') } catch { /* ignore error, continue */ }
      }
    } else {
      if (token === 'undefined') {
        try { localStorage.removeItem('token') } catch { /* ignore error, continue */ }
        try { sessionStorage.removeItem('token') } catch { /* ignore error, continue */ }
      }
    }
  }

  async function checkMSALAccountAgainstPCASecurityMapping() {
    let msalcheck = '';
    msalcheck = sessionStorage.getItem('msalcheck') ?? '';
    if (msalcheck === '') {
      sessionStorage.setItem('msalcheck', 'done');
      CheckAndAdd_MSALUserAccount();
    }
  }

  checkPCAppsTokenExpired();

  function handleStartSignIn() {
    // sign in
    msalLoginRedirect();
    localStorage.setItem('logintype', 'msal');

  }

  function handleStartSignInPCASecurity() {
    // sign in
    setIsUsePCAppsSecurity(true);
  }

  function handlePCASecurityForgotPassword() {
    // sign in
    setIsShowForgotPassword(true);
  }

  function handlePCASecurityForgotPasswordSubmitted() {
    // sign in
    setIsShowForgotPassword(false);
  }


  function IsAuthenticated() {
    let isAuth = false;
    isAuth = useIsAuthenticated();
    if (isAuth === false) {
      localStorage.getItem('token') ? isAuth = true : isAuth = false
      if (isAuth === false) {
        sessionStorage.getItem('token') ? isAuth = true : isAuth = false
      }
    }

    return isAuth;
  }

  return (
    <>
      <Helmet>
        <title>Powers of Louisiana HVAC - Commission Manager</title>
      </Helmet>

      {

        (IsAuthenticated() === false ?
          (
            // msalContext.inProgress === InteractionStatus.None && 
            //                       <LoginPageAzureADMSAL onStartSignIn={handleStartSignIn} />
            isShowForgotPassword ?
              <ForgotPassword onForgotPasswordSubmitted={handlePCASecurityForgotPasswordSubmitted} />
              :
              (
                isUsePCAppsSecurity ? <LoginPagePCApps onForgotPassword={handlePCASecurityForgotPassword} />
                  : msalContext.inProgress === InteractionStatus.None &&
                  <LoginPageAzureADMSAL onStartSignIn={handleStartSignIn}
                    onStartSignInPCApps={handleStartSignInPCASecurity} />
              )

          )
          :

          <div css={styles}>
            <script onLoad={checkMSALAccountAgainstPCASecurityMapping}></script>

            <AppHeader />
            <main>
              <Outlet />
            </main>
            <AppFooter />
          </div>
        )

      }
    </>
  );
}


export default App;
