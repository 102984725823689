import React from 'react';
import { Button as ButtonEx } from 'devextreme-react/button';

import {
  Button,
  DataGrid,
  Column, ColumnFixing,
  Export,
  Editing,
  ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
  HeaderFilter, Search, SearchPanel,
  
} from 'devextreme-react/data-grid';

import type { PCAUsers } from '../../api/types/PCAUsers';

interface CtrGridUsersProps {
  dataList: PCAUsers[];
  onRowClicked?: (_host: PCAUsers) => void;
  // onHostAction: (_action: string, _host: PCAUsers) => void;
  onAddRecordClick?: () => void;
  onEditRecordClick?: (_host: PCAUsers) => void;
}


const CtrGridUsers = React.memo(function CtrGridUsers({
  dataList,
  onRowClicked,
  // onHostAction,
  onAddRecordClick,
  onEditRecordClick,
}: CtrGridUsersProps) {
  // create columns



  
  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
        if (item.name === "searchPanel") {
            item.location = 'before';
            }
        if (item.name === "groupPanel") {
            item.location = 'after';
            }
        })
  }
  function handleRowClick(e: PCAUsers) {
    

    //if (isEditableGrid === false) {
    onRowClicked(e);
    //}
  }

  const renderButtonCellHeader = () => <div> 
  <ButtonEx 
    width={30}
    style={{backgroundColor: 'transparent', margin: '0x',  paddingLeft: '5px',}}
    id={'dxGridAddButton'}
    text="contained"
    type="default"
    icon="add"
    onClick={onAddRecordClick}
  /></div>

  return (
    <div >
      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="userGuid"
        allowColumnResizing={true}
        onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        height={500}
        width={'100%'}
        style={{margin: 0, marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={true}
          width={250}
          placeholder="Search..." />
        <Editing
        mode="popup"
        useIcons={true}
        allowUpdating={true}
        />
        <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' 
          allowFiltering={false} allowEditing={false} allowReordering={false} allowResizing={false} allowHiding={false} allowGrouping={false} allowHeaderFiltering={false} allowExporting={false} 
          headerCellRender={renderButtonCellHeader} >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column>

        <Column dataField="userName" caption='User Name' width={150} ></Column>
        <Column dataField="firstName" caption='First Name' width={150} ></Column>
        <Column dataField="lastName" caption='Last Name' width={150} ></Column>
        <Column dataField="employeeTitle" caption='Title' width={150} ></Column>
        <Column dataField="officeLocation" caption='Office' width={150} ></Column>
        <Column dataField="emailAddress" caption='Email' width={200} ></Column>
        <Column dataField="isEnabled" caption='Enabled ?' width={100} dataType='boolean' ></Column>
        <Column dataField="isLocked" caption='Locked ?' width={100} dataType='boolean' ></Column>
                
        <Column dataField="createdOn" caption='Created On' width={150} dataType='datetime' ></Column>
        <Column dataField="createdBy" caption='Created By' width={120} ></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime'  visible={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false}></Column>
        
        <ColumnFixing enabled={true} />
        <ColumnChooser
          enabled={true}
          mode={"select"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
            />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={true} allowExportSelectedData={true} />
        

      </DataGrid>

    </div>
  );
});


export default CtrGridUsers;
