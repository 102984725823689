import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { ProjectCommissionPayout } from './types/ProjectCommissionPayout';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function ProjectsCommissionPayouts_GetForProject(projectGuid: string): Promise<ProjectCommissionPayout[]> {
  const url = `${apiBaseUrl}ProjectsCommissionPayouts_GetForProject?projectGuid=${projectGuid}`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useProjectsCommissionPayouts_GetForProject(projectGuid: string) {
  const [data, setData] = useState<ProjectCommissionPayout[]>([]);
  
  

  const loaderState = useApiLoader<ProjectCommissionPayout[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await ProjectsCommissionPayouts_GetForProject( projectGuid);
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [projectGuid]
    )
  );

 
  return { data, ...loaderState };
}

export { ProjectsCommissionPayouts_GetForProject, useProjectsCommissionPayouts_GetForProject };
