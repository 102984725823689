import { Button } from 'devextreme-react/button';

function AddChangeOrderButton({ disabled = false, onClick }) {
  return (
    <Button
      width={300}
      id={'dxAddChangeOrderButton'}
      text="contained"
      type="default"
      disabled={disabled}
      onClick={onClick}>
      Add Change Order For Selected Project
    </Button>
  );
}

export default AddChangeOrderButton;
