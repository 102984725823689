import { css } from '@emotion/react';
import * as packageJson from './../../../package.json' ;

const styles = css`
  display: flex;
  margin-block: 18px;
  font-size: 0.875rem;
`;

const copyrightStyles = css`
  margin-left: auto;
`;

const appVersion: string = packageJson.version;

function AppFooter() {
  return (
    <footer style={{padding: 0, height: '20px', margin: 0, marginRight: '10px', display: 'flex', fontFamily: 'var(--app-ui-font-family)', fontSize: '11px'}}>
      <span css={copyrightStyles}><a href="https://www.pcapps.com" target='blank'>Copyright &copy; 1992-2024 Practical Computer Applications, Inc. All rights reserved.  Powered with React.  App version: <b>{appVersion}</b> </a></span>
    </footer>
  );
}

export default AppFooter;
