import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/msalConfig';
import { AppContextStoreProvider } from './lib/appContextStore';
import { routes } from './routes';
import '/main.css';
import { setMsalInstance } from './lib/msal';


const router = createHashRouter(routes);

const msalInstance = new PublicClientApplication(msalConfig);
setMsalInstance(msalInstance);

const containerElement = document.getElementById('root');
const root = createRoot(containerElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppContextStoreProvider>
        <MsalProvider instance={msalInstance}>
          <RouterProvider router={router}  />
        </MsalProvider>
      </AppContextStoreProvider>
    </HelmetProvider>
  </React.StrictMode>
);
