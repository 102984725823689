import { ChangeEvent } from 'react';
import React, { useMemo, useState } from 'react';
import { styles } from '../shared/Styles';
import Moment from 'moment';

import 'devextreme/dist/css/dx.light.css';
 
import {
    Button,
    DataGrid,
    Column, ColumnFixing,
    Export,
    Editing,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
    DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
    Lookup,
    RequiredRule,
    DataGridRef,
    
} from 'devextreme-react/data-grid';


import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { css } from '@emotion/react';
import { ProjectCommissionPayout } from '/api/types/ProjectCommissionPayout';
import { CommissionPayoutStatus } from '/api/types/CommissionPayoutStatus';
import { FocusedCellChangedEvent, RowUpdatedEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ProjectsCommissionPayouts_Edit } from '/api/ProjectsCommissionPayouts_Edit';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PCApps-TimesheetDXExport.xlsx');
    });
  });
};


interface CtrGridCommissionPayoutsProps {
  className?: string;
  allowInGridEditing?: boolean;
  currentFilterDateStart?: string;
  dataList: ProjectCommissionPayout[];
  commissionPayoutStatuses: CommissionPayoutStatus[];
  windowWidth: number;
  windowHeight: number;
  onRowClicked?: (_host: ProjectCommissionPayout) => void;
  onRowDoubleClicked?: (_host: ProjectCommissionPayout) => void;
  onAddRecordClick?: (_host: ProjectCommissionPayout) => void;
  onEditRecordClick?: (_host: ProjectCommissionPayout) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;

}


const CtrGridCommissionPayouts = React.memo(function CtrGridCommissionPayouts({
  className,
  allowInGridEditing = true,
  currentFilterDateStart = Moment(new Date(Date.now())).format('YYYY-MM-DD'),
  dataList,
  commissionPayoutStatuses,
  windowWidth = 1024,
  windowHeight = 800,
  onRowClicked,
  onRowDoubleClicked,
  onAddRecordClick,
  onEditRecordClick,
  onRefreshParentComponent,
}: CtrGridCommissionPayoutsProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false)

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked)
    onRefreshParentComponent(false)
  }

  function handleRowClick(e: ProjectCommissionPayout) {
    

    //if (isEditableGrid === false) {
    onRowClicked(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
      if (item.name === "searchPanel") {
        item.location = 'before';
      }
      if (item.name === "groupPanel") {
        item.location = 'after';
      }
    })
  }
  // --------------------------------------
  async function saveChanges(updatedRow: ProjectCommissionPayout) {
    const results = await ProjectsCommissionPayouts_Edit(updatedRow);

    if (!results) {
      alert('Save failed');
      return;
    }

    return updatedRow;
  }

  const onRowUpdated = (e: RowUpdatedEvent) => {
    console.log('onRowUpdated');
    console.log(e.data);

    saveChanges(e.data);
    onRefreshParentComponent(true);
    // if (e.data.changes.length) {
    //   saveChanges(e.data.changes, e.component);
    // }
  };
  
  //In-Line grid editing
  //const [rows, setRows] = React.useState(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));
  const screenWidthAdjustGridWidth = ((windowWidth < 900 && windowWidth > 100 ? (windowWidth - 75).toString() + 'px' : (windowWidth - 75).toString() + 'px'));

  return (

      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="projectCommissionPayoutGuid"
        allowColumnResizing={true}
        columnResizingMode='widget'
        onExporting={onExporting}
        //onRowClick={(e) => handleRowClick(e.data)}
        onRowUpdated={onRowUpdated}
        onToolbarPreparing={onToolbarPreparing}
        height={280}
        width={screenWidthAdjustGridWidth}
        style={{marginLeft: '10px', marginBottom: '0px'}}
      >
        <Scrolling mode="infinite" />
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={false}
          width={250}
          placeholder="Search..." />
        <Editing
        mode="cell"
        useIcons={false}
        allowUpdating={true}
        />
        <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column>
        <Column dataField="commissionPayoutStatusId" caption='Payout Status' width={140} allowEditing={true} cssClass='dxGridColumnEditable'>
          <Lookup
              dataSource={commissionPayoutStatuses}
              displayExpr="commissionPayoutStatusName"
              valueExpr="commissionPayoutStatusId"
            />
            <RequiredRule />
        </Column>
        <Column dataField="commissionPayoutAmount" caption='Comm Payout Amount' width={180} format={'$#,###,###,##0.00'} allowEditing={false}>
          <RequiredRule />
        </Column>
        
        <Column dataField="notes" caption='Comments/Notes'  allowEditing={true} minWidth={120} cssClass='dxGridColumnEditable' defaultSortOrder='asc'></Column>
                
        <Column dataField="createdOn" caption='Created On' width={150} dataType='datetime' visible={true} allowEditing={false} defaultSortOrder='asc'></Column>
        <Column dataField="createdBy" caption='Created By' width={120} visible={false} allowEditing={false}></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime'  visible={false} allowEditing={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false} allowEditing={false}></Column>
        
        <ColumnFixing enabled={true} />
        <ColumnChooser
          enabled={false}
          mode={"select"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
            />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={false} allowExportSelectedData={true} />
        
        <Summary>
          <TotalItem
            column="commissionPayoutAmount"
            summaryType="sum"
            valueFormat='$#,##0.00'
            displayFormat='Total: {0}'
            showInColumn='commissionPayoutAmount'
            
            />
        </Summary>
      </DataGrid>


  );



});


export default CtrGridCommissionPayouts;
