import React, { useState, useCallback } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { css } from '@emotion/react';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { Drawer, Box, Typography, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { msalLogoutRedirect } from '/lib/msal';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { PageCanAccessProjects, PageCanAccessDashboard, PageCanAccessWebUserSecurity, PageCanAccessUtilities } from '/lib/appContextStore';
//import { usePCAWebsite_CanAccessPages } from '../../api/PCAWebsite_CanAccessPages';
import Menu, { MenuTypes, Item } from 'devextreme-react/menu';


const bodyStyles = css`
  line-height: 40px;
  verticle-align: middle;
  font-family: var(--app-ui-font-family);
`;

const accountButtonStyles = css`
  margin-left: auto;
  padding-left: 2px;
  border: 0px;
  background-color: white;
`;
const accountIconStyles = css`
  font-size: 28px;
`;

const menuIconStyles = css`
  margin-left: 9px;
  font-size: 26px;
`;

function AppMainMenu() {
  //const { data: privilegesList } = usePCAWebsite_CanAccessPages();

  function handleStartSignOut() {
    try { localStorage.removeItem('token'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('token'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('logintype'); } catch { /* ignore error, continue */ }
    try { sessionStorage.removeItem('msalcheck'); } catch { /* ignore error, continue */ }

    try { msalLogoutRedirect(); } catch { /* ignore error, continue */ }

    window.location.reload();
  }

  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false)

    const utilitiesMenu : UtilityType[]= [
      {
        text: 'Utilities',
        items: [
          {
            text: 'Setup Job Types',
          },
          {
            text: 'Setup Salesperson Rates',
          },
        ]
  }]

  interface UtilityItemType {
    text: string,
  }
  
  interface UtilityType {
    text: string,
    items: (UtilityItemType | UtilityType)[],
  }
  const [currentUtility, setCurrentUtility] = useState(null);
  const SUBMENU_HEIGHT = 200;
  const itemClick = useCallback((e: MenuTypes.ItemClickEvent & { itemData: UtilityItemType }) => {
    setCurrentUtility(e.itemData);
  }, [setCurrentUtility]);

  
  // const onSubmenuShowing = useCallback(({ submenuContainer: Any }: HTMLElement) => {
  //   submenuContainer.style.maxHeight = false ? `${SUBMENU_HEIGHT}px` : '';
  // }, []);


  return (
    <>
      <nav>
        <IconButton size='large' edge='start' color='inherit' aria-label='logo' onClick={() => setIsMenuDrawerOpen(true)}><MenuIcon /></IconButton>
        <Drawer

          anchor='left'
          open={isMenuDrawerOpen}
          onClose={() => setIsMenuDrawerOpen(false)}
          ModalProps={{ keepMounted: true }}
        >
          <Box p={2} width='150px' textAlign={'left'} role='presentation' >

            <Typography variant='h6' component='div'>
              Main Menu
              <IconButton size='large' edge='start' color='inherit' aria-label='logo' onClick={() => setIsMenuDrawerOpen(false)}><MenuIcon css={menuIconStyles} /></IconButton>

            </Typography>
            <hr />
            <br />
            <div css={bodyStyles}>
              <ul>

                {PageCanAccessProjects() ?
                  (
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='home'>
                        <IconButton css={accountButtonStyles}>
                          <WorkHistoryOutlinedIcon css={accountIconStyles} />
                        </IconButton>
                        Projects
                      </NavLink>
                    </li>
                  ) : '' }

                  {PageCanAccessDashboard() ?
                   ( 
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='dashboard'>
                        <IconButton css={accountButtonStyles}>
                          <DashboardOutlinedIcon css={accountIconStyles} />
                        </IconButton>
                        Dashboard
                      </NavLink>
                    </li>
                   ) : '' }

                  {PageCanAccessUtilities() ?
                  ( 
                    <li>
                      <IconButton css={accountButtonStyles}>
                        <BuildCircleOutlinedIcon css={accountIconStyles} />
                      <Menu
                          className='nav-link'
                          //onSubmenuShowing={onSubmenuShowing}
                          showFirstSubmenuMode={'onHover'}
                          orientation={'vertical'}
                          //hideSubmenuOnMouseLeave={false}
                          onItemClick={itemClick}
                        >
                          <Item
                              text="Utilities"
                          >
                            <Item>
                              <NavLink className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='jobtypes'>
                                Setup Job Types
                              </NavLink>
                            </Item>
                            <Item>
                              <NavLink className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='salesRates'>
                                Setup Salesperson Rates
                              </NavLink>
                            </Item>
                          </Item>
                          
                        </Menu>
                        </IconButton>
                    </li>
                  ) : '' }
                
                {PageCanAccessWebUserSecurity() ?
                  (
                    <li>
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link--active' : 'nav-link')} to='users'>
                        <IconButton css={accountButtonStyles}>
                          <PeopleOutlineIcon css={accountIconStyles} />
                        </IconButton>
                        Users
                      </NavLink>
                    </li>
                  )
                  :
                  ''
                } 
              </ul>
            </div>
            <br />
            <hr />
            <br />
            <IconButton css={accountButtonStyles} onClick={handleStartSignOut}>
              <AccountCircle css={accountIconStyles} />
            </IconButton>
            <button onClick={handleStartSignOut} css={accountButtonStyles} >
              Sign Out
            </button>
          </Box>
        </Drawer>
      </nav>
    </>
  );

}


export default AppMainMenu;