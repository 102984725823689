import React, { useMemo } from 'react';
//import { DataGridPremium } from '@mui/x-data-grid-premium';
//import type { GridColDef,  GridRowModel, } from '@mui/x-data-grid-premium';
import type { Roles } from '../../api/types/Roles';
import { UsersOrgsRoles_Assign } from '/api/UsersOrgsRoles_Assign';

import {
  Button,
  DataGrid,
  Column, ColumnFixing,
  Export,
  Editing,
  ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
  DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
  
} from 'devextreme-react/data-grid';


interface CtrGridRolesProps {
  className?: string;
  dataList: Roles[];
// onRowClicked?: (_host: TicketComment) => void;
// onRowDoubleClicked?: (_host: TicketComment) => void;
// // onHostAction: (_action: string, _host: Ticket) => void;
// onAddTicketsClick: (_host: TicketComment) => void;
// onEditTicketsClick: (_host: TicketComment) => void;
// onRefreshParentComponent?: (_isRefetchData: boolean) => void;
}

const CtrGridRoles = React.memo(function CtrGridRoles({
  className,
  dataList,
// onRowClicked,
// // onHostAction,
// onAddTicketsClick,
// onEditTicketsClick,
// onRefreshParentComponent,
}: CtrGridRolesProps) {
  // create columns

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
        if (item.name === "searchPanel") {
            item.location = 'before';
            }
        if (item.name === "groupPanel") {
            item.location = 'after';
            }
        })
  }
  function handleRowClick(e: Roles) {
    

    //if (isEditableGrid === false) {
    //}
  }
  const handleRowUpdated = async (e: Roles) => {

    await UsersOrgsRoles_Assign(e);
    
  };

  return (
    <div className={className}>
      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="roleGuid"
        allowColumnResizing={true}
        onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        onRowUpdated={(e) => handleRowUpdated(e.data)}
        height={300}
        width={'100%'}
        style={{marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={false} />
        <SearchPanel
          visible={false}
          width={250}
          placeholder="Search..." />
        <Editing
        mode="cell"
        useIcons={true}
        allowUpdating={true}
        />
        {/* <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' 
          allowFiltering={false} allowEditing={false} allowReordering={false} allowResizing={false} allowHiding={false} allowGrouping={false} allowHeaderFiltering={false} allowExporting={false} 
          >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column> */}

        <Column dataField="roleName" caption='Role' width={200} ></Column>
        <Column dataField="isMember" caption='Member' width={100} ></Column>
        <Column dataField="Spacer" caption='' allowFiltering={false} allowEditing={false} allowHeaderFiltering={false}  ></Column>
        <ColumnFixing enabled={true} />
        <ColumnChooser
          enabled={false}
          mode={"select"}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={false}
            />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={false} allowExportSelectedData={true} />
        

      </DataGrid>


    </div>
  );
});


export default CtrGridRoles;
