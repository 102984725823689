import { useCallback, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import type {} from '@mui/x-data-grid/themeAugmentation';
import ResponsiveBox, { Row, Col, Location } from 'devextreme-react/responsive-box';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, DataGridTypes } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import RadioGroup, { RadioGroupTypes } from 'devextreme-react/radio-group';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import RefreshButton from '../shared/RefreshButton';
import ProgressSpinner from '../shared/ProgressSpinner';

import ControlGridCommissionPayouts from './CtrGridCommissionPayouts';
import ControlGridProjects from './CtrGridProjects';
import ControlGridProjectsChangeOrders from './CtrGridProjectsChangeOrders';
import ControlGridDashboardSalesperson from './CtrGridDashboardSalesperson';

import ControlActionDialogConfirmHost, {
  useConfirmHostActionDialog,
} from '../shared/CtrActionDialogConfirmHost';
import { useProjects_GetForBrowseListView } from '../../api/Projects_GetForBrowseListView';
import { PageCanAccessProjectsAdminFeatures, useActiveUser } from '/lib/appContextStore';

import useHostAction from '../../lib/useHostAction';
import type { Project } from '../../api/types/Project';
import type { ProjectCommissionPayout } from '../../api/types/ProjectCommissionPayout';

import { canAccessPage } from '/lib/canAccessPage';
import { useDashboardMonthlyPayouts_Get } from '/api/DashboardMonthlyPayouts_Get';
import { useProjectsCommissionPayouts_GetForProject } from '/api/ProjectsCommissionPayouts_GetForProject';
import { useProjectsChangeOrders_GetForProject } from '/api/ProjectsChangeOrders_GetForProject';
import { useJobTypes_GetAll } from '/api/JobTypes_GetAll';
import { useProjectStatus_GetAll } from '/api/ProjectStatus_GetAll';
import { useCommissionPayoutStatuses_GetAll } from '/api/CommissionPayoutStatuses_GetAll';
import { useSalesperson_GetAll } from '/api/Salesperson_GetAll';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import AddChangeOrderButton from './AddChangeOrderButton';
import { ProjectsChangeOrders_Add } from '/api/ProjectsChangeOrders_Add';
import SubmitForReviewButton from './SubmitForReviewButton';
import { Projects_SubmitForReview } from '/api/Projects_SubmitForReview';

const progressSpinnerStyles = css`
  margin-left: 16px;
  vertical-align: middle;
`;

function ProjectBrowse() {
  // global context
  const currentYear: number = new Date().getFullYear();
  const [filterSelectTopN, setFilterSelectTopN] = useState('1,000');
  const [filterProjectID, setFilterProjectID] = useState('');
  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterFirstName, setFilterFirstName] = useState('');
  const [filterLastName, setFilterLastName] = useState('');
  const [filterItemsForSale, setFilterItemsForSale] = useState('');
  const [filterProjectStatus, setFilterProjectStatus] = useState('Active');
  const [filterStartYear, setFilterStartYear] = useState<number>(currentYear - 1);
  const [filterStopYear, setFilterStopYear] = useState<number>(currentYear);
  const showYearRecords: number[] = Array.from({ length: 6 }, (_, i) => currentYear + 1 - i);
  const [focusedRowKey, setFocusedRowKey] = useState('');

  const [isProjectAddEditDrawerOpen, setIsProjectAddEditDrawerOpen] = useState(false);
  const [currentProjectData, setCurrentProjectData] = useState<Project>();
  const [currentProjectGuid, setCurrentProjectGuid] = useState('');
  const [currentCheckNumber, setCurrentCheckNumber] = useState('');
  const [currentProjectNoteID, setCurrentProjectNoteID] = useState(0);
  const isSalespersonLoggedIn = PageCanAccessProjectsAdminFeatures();
  
  // const currentUser = useActiveUser();
  const [filterSalesPersonUserName, setFilterSalesPersonUserName] = useState('');
  // const [filterSalesPersonUserName, setFilterSalesPersonUserName] = useState(isSalespersonLoggedIn ? '' : currentUser?.userName);

  const clickAddProjectOpen = () => {
    setCurrentProjectGuid('');
    setIsProjectAddEditDrawerOpen(true);
    setCurrentProjectData(null);
    refetchProjectChangeOrders();
    refetchProjectChangeOrders();
  };

  const clickEditProjectOpen = (ts: Project) => {
    setCurrentProjectGuid(ts.projectGuid);
    setCurrentProjectData(ts);
    setIsProjectAddEditDrawerOpen(true);
  };
  const GridRowClicked = async (ts: Project) => {
    if (ts == null) return;
    await setFocusedRowKey(ts.projectGuid ?? '');
    await setCurrentProjectGuid(ts.projectGuid);
    await setCurrentProjectData(ts);
    await setCurrentProjectNoteID(0);
    await setCurrentCheckNumber('');
    refetchProjectCommissionPayouts();
    refetchProjectChangeOrders();
  };
  const handleToggleDrawerProjectAddEdit = () => {
    setIsProjectAddEditDrawerOpen(false);
  };

  const RefreshButtonClicked = async () => {
    refetchProjects();
    refetchDashboards();
  };

  const AddChangeOrderButtonClicked = async () => {
    const { retProjectGuid } = await ProjectsChangeOrders_Add(currentProjectGuid);
    await refetchProjects();
    setFocusedRowKey(retProjectGuid ?? '');
  };

  const SubmitForReviewButtonClicked = async () => {
    if (currentProjectData !== undefined) {
      if (currentProjectData.projectStatusId === 1 || currentProjectData.projectStatusId === 6) {
        await Projects_SubmitForReview(currentProjectGuid);
        await refetchProjects();
      }
    }
  };
  

  const dataGridOnSelectionChanged = (e: DataGridTypes.SelectionChangedEvent) => {
    // setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
    setGridBoxValue(e.selectedRowKeys.map(i => Number(i)));
  };

  const syncDataGridSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    //setGridBoxValue(e.value || []);
  }, []);

  const {
    data: ProjectStatusList,
    isLoading: loadingProjectStatusList,
    refetch: refetchProjectStatusList,
  } = useProjectStatus_GetAll();

  const gridColumns = ['projectStatusName'];
  const [gridBoxValue, setGridBoxValue] = useState<number[]>([1, 2, 3]);
  const dataGridRender = useCallback(
    () => (
      <DataGrid
        height={345}
        keyExpr="projectStatusId"
        dataSource={ProjectStatusList}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={dataGridOnSelectionChanged}>
        <Selection mode="multiple" selectByClick={true} />
        <Scrolling mode="virtual" />
        <FilterRow visible={false} />
      </DataGrid>
    ),
    [ProjectStatusList, gridBoxValue]
  );

  // load Projects list //string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
  const {
    data: Projects,
    isLoading: loadingProjects,
    refetch: refetchProjects,
  } = useProjects_GetForBrowseListView(
    filterSalesPersonUserName,
    gridBoxValue,
    filterStartYear,
    filterStopYear
  );

  const {
    data: Dashboards,
    isLoading: loadingDashboards,
    refetch: refetchDashboards,
  } = useDashboardMonthlyPayouts_Get(filterSalesPersonUserName, filterStartYear, filterStopYear);

  const {
    data: ProjectCommissionPayouts,
    isLoading: loadingProjectCommissionPayouts,
    refetch: refetchProjectCommissionPayouts,
  } = useProjectsCommissionPayouts_GetForProject(currentProjectGuid === '' ? '00000000-0000-0000-0000-000000000000' : currentProjectGuid);

  const {
    data: CommissionPayoutStatuses,
    isLoading: loadingCommissionPayoutStatuses,
    refetch: refetchCommissionPayoutStatuses,
  } = useCommissionPayoutStatuses_GetAll();

  const {
    data: ProjectChangeOrders,
    isLoading: loadingProjectChangeOrders,
    refetch: refetchProjectChangeOrders,
  } = useProjectsChangeOrders_GetForProject(currentProjectGuid === '' ? '00000000-0000-0000-0000-000000000000' : currentProjectGuid);

  const { data: JobTypes, isLoading: loadingJobTypes, refetch: refetchJobTypes } = useJobTypes_GetAll();

  const {
    data: Salespersons,
    isLoading: loadingSalespersons,
    refetch: refetchSalespersons,
  } = useSalesperson_GetAll();

  const [dataLastRefreshedOn, setDataLastRefreshedOn] = useState(new Date(Date.now()));
  const [lastRefreshedOn, setLastRefreshedOn] = useState(new Date(Date.now()));
  const refreshParentComponent = (isRefetchData: boolean) => {
    const t = new Date(lastRefreshedOn);
    t.setSeconds(t.getSeconds() + 3);
    setLastRefreshedOn(t);

    if (isRefetchData) {
      setDataLastRefreshedOn(t);
      refetchProjects();
      refetchDashboards();
    }
  };
  
  // host action
  const { executeAction: handleExecuteHostAction, isLoading: loadingHostAction } =
    useHostAction(refetchProjects);

  // confirm host action dialog
  const { confirmHostActionState, hideConfirmHostActionDialog } = useConfirmHostActionDialog();

  const loadingData =
    loadingProjects ||
    loadingProjectCommissionPayouts ||
    loadingHostAction ||
    loadingJobTypes ||
    loadingProjectStatusList;

  // const showSalesPerson = ['Daniel Moore', 'John Smith', 'Judy Jones', 'Richard Reynolds', 'Wendy Williams'];

  const ChangeOrderClicked = async (ts: Project) => {
    if (ts == null) return;

    const clonedItem = { ...ts, projectGuid: null };
    Projects.splice(0, 0, clonedItem);
  };

  // const projectStatusSource =   new CustomStore({
  //   loadMode: 'raw',
  //   key: 'ID',
  //   load() {
  //     return [{
  //       "ID": 1,
  //       "Name": "New"
  //   }, {
  //       "ID": 2,
  //       "Name": "Review"
  //   }, {
  //       "ID": 3,
  //       "Name": "Open"
  //   }, {
  //       "ID": 4,
  //       "Name": "Completed"
  //   }, {
  //       "ID": 5,
  //       "Name": "Cancelled"
  //   }, {
  //     "ID": 6,
  //     "Name": "Require Info"
  //   }
  //   ];
  //   },
  // });

  const statusLabel = { 'aria-label': 'Status' };

  const statusEditorRender = cell => {
    const onValueChanged = (e: SelectBoxTypes.ValueChangedEvent) => cell.setValue(e.value);

    const itemRender = data => {
      //const imageSource = `images/icons/status-${data.id}.svg`;

      if (data != null) {
        return (
          <div>
            {/* <img src={imageSource} className="status-icon middle"></img> */}
            <span className="middle">{data.name}</span>
          </div>
        );
      }

      return <span>(All)</span>;
    };

    return (
      <SelectBox
        defaultValue={cell.value}
        searchMode={'startswith'}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        inputAttr={statusLabel}
        itemRender={itemRender}
      />
    );
  };

  const salesPersonValueChanged = useCallback((e: ValueChangedEvent) => {
    setFilterSalesPersonUserName(e.value || []);
  }, []);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWindowWidth]);

  const screenByWidthFilterSection = (width: number) => (width < 900 ? 'sm' : 'lg');
  const screenByWidthCommissionPayoutTab = (width: number) => (width < 900 ? 'sm' : 'lg');
  const screenWidthAdjustFilterContainer = windowWidth < 900 ? 410 : 750;
  const screenWidthAdjustFilterTextInput = windowWidth < 900 ? '195px' : '150px';
  const screenWidthAdjustWidthDetailTab =
    windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%';
  const screenWidthAdjustHeightDetailTab = windowWidth < 900 ? '720' : '390';
  const screenWidthAdjustBody =
    windowWidth !== 0 ? (((windowWidth - 40) / windowWidth) * 100).toString() + 'vw' : '96vw';

  const ownerLabel = { 'aria-label': 'Owner' };

  return (
    <div style={{ padding: '0px', margin: '0px', width: screenWidthAdjustBody }}>
      <h1>Projects</h1>

      {/* <ResponsiveBox
          screenByWidth={screenByWidthFilterSection}
          width={screenWidthAdjustFilterContainer}
        >
          <Row ratio={1}></Row>
          <Row ratio={1}></Row>
          
          
          <Col ratio={1} baseSize={150} ></Col>
          <Col ratio={1} baseSize={150}  ></Col>
          <Col ratio={1} baseSize={100}  ></Col>

          {
            isSalespersonLoggedIn
            ? <Item>
              <Location screen="lg" row={0} col={0}></Location>
              <Location screen="sm" row={0} col={0} ></Location>
              <SelectBox
                    items={showSalesPerson}
                    inputAttr={{'aria-label': 'Simple Product'}}
                    defaultValue={''}
                    onValueChanged={useCallback(({ component }) => {setFilterSelectTopN(component.option('selectedItem')); }, []) }
                    label='Salesperson(s)'
                    width={250}
                  />
            </Item> : ''
          }
          
          <Item>
            <Location screen="lg" row={0} col={(isSalespersonLoggedIn ? 1 : 0)}></Location>
            <Location screen="sm" row={0} col={(isSalespersonLoggedIn ? 1 : 0)} ></Location>
            <DropDownBox
            
            //value={projectStatusValue}
            // valueExpr="ID"
            // displayExpr="Name"
            // label='Project Status'
            // placeholder="Select a value..."
            // showClearButton={true}
            // dataSource={projectStatusSource}
            value={gridBoxValue}
            valueExpr="ID"
            label='Project Status'
            inputAttr={ownerLabel}
            deferRendering={true}
            displayExpr="Name"
            placeholder="Select a value..."
            
            dataSource={projectStatusSource}
            onValueChanged={syncDataGridSelection}
            contentRender={dataGridRender}
            width={250}
          />
                
          </Item>
          
          <Item>
            <Location screen="lg" row={0} col={2}></Location>
            <Location screen="sm" row={0} col={2} ></Location>
            <div style={{marginTop: '5px'}}><RefreshButton  disabled={loadingData} onClick={refetchProjects} /><ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} /></div>
          </Item>
        </ResponsiveBox> */}
      {PageCanAccessProjectsAdminFeatures() === false ? (
        <table>
          <tr>
            <td>
              <DropDownBox
                value={gridBoxValue}
                valueExpr="projectStatusId"
                label="Project Status"
                inputAttr={ownerLabel}
                deferRendering={true}
                displayExpr="projectStatusName"
                placeholder="Select a value..."
                dataSource={ProjectStatusList}
                onValueChanged={syncDataGridSelection}
                contentRender={dataGridRender}
                width={280}
              />
            </td>
            <td>
              <div>
                <SelectBox
                  items={showYearRecords}
                  inputAttr={{ 'aria-label': 'Simple Product' }}
                  acceptCustomValue={true}
                  defaultValue={filterStartYear}
                  onValueChanged={({ component }) => {
                    const selectedYear: number = component.option('selectedItem');
                    setFilterStartYear(selectedYear);
                    if (selectedYear > filterStopYear) {
                      setFilterStopYear(selectedYear);
                    }
                  }}
                  label="Start Year"
                  width={screenWidthAdjustFilterTextInput}
                />
              </div>
            </td>
            <td>
              <div>
                <SelectBox
                  items={showYearRecords}
                  inputAttr={{ 'aria-label': 'Simple Product' }}
                  acceptCustomValue={true}
                  defaultValue={filterStopYear}
                  onValueChanged={({ component }) => {
                    const selectedYear: number = component.option('selectedItem');
                    setFilterStopYear(selectedYear);
                    if (selectedYear < filterStartYear) {
                      setFilterStartYear(selectedYear);
                    }
                  }}
                  label="Stop Year"
                  width={screenWidthAdjustFilterTextInput}
                />
              </div>
            </td>
            <td style={{ paddingLeft: '20px' }}>
              <RefreshButton disabled={loadingData} onClick={RefreshButtonClicked} />
            </td>
            <td style={{ width:'40px' }}>
              <ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} />
            </td>
            <td style={{ paddingLeft: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AddChangeOrderButton disabled={loadingData} onClick={AddChangeOrderButtonClicked} />
              </div>
            </td>
            <td>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SubmitForReviewButton disabled={loadingData} onClick={SubmitForReviewButtonClicked} visible={(currentProjectData?.projectStatusId === 1 || currentProjectData?.projectStatusId === 6) ?? true} />
              </div>
            </td>
          </tr>
        </table>
      ) : (
        <table>
          <tr>
            <td>
              <SelectBox
                dataSource={Salespersons}
                valueExpr={'salesPersonUserName'}
                displayExpr={'salesPersonUserName'}
                inputAttr={{ 'aria-label': 'Simple Product' }}
                defaultValue={''}
                onValueChanged={salesPersonValueChanged}
                searchEnabled={true}
                searchMode={'startswith'}
                searchExpr={'salesPersonUserName'}
                label="Salesperson(s)"
                width={250}
              />
            </td>
            <td>
              <DropDownBox
                value={gridBoxValue}
                valueExpr="projectStatusId"
                label="Project Status"
                inputAttr={ownerLabel}
                deferRendering={true}
                displayExpr="projectStatusName"
                placeholder="Select a value..."
                dataSource={ProjectStatusList}
                onValueChanged={syncDataGridSelection}
                contentRender={dataGridRender}
                width={280}
              />
            </td>
            <td>
              <div>
                <SelectBox
                  items={showYearRecords}
                  inputAttr={{ 'aria-label': 'Simple Product' }}
                  acceptCustomValue={true}
                  value={filterStartYear}
                  onValueChanged={({ component }) => {
                    const selectedYear: number = component.option('selectedItem');
                    setFilterStartYear(selectedYear);
                    if (selectedYear > filterStopYear) {
                      setFilterStopYear(selectedYear);
                    }
                  }}
                  label="Start Year"
                  width={screenWidthAdjustFilterTextInput}
                />
              </div>
            </td>
            <td>
              <div>
                <SelectBox
                  items={showYearRecords}
                  inputAttr={{ 'aria-label': 'Simple Product' }}
                  acceptCustomValue={true}
                  value={filterStopYear}
                  onValueChanged={({ component }) => {
                    const selectedYear: number = component.option('selectedItem');
                    setFilterStopYear(selectedYear);
                    if (selectedYear < filterStartYear) {
                      setFilterStartYear(selectedYear);
                    }
                  }}
                  label="Stop Year"
                  width={screenWidthAdjustFilterTextInput}
                />
              </div>
            </td>
            <td style={{ paddingLeft: '20px' }}>
              <RefreshButton disabled={loadingData} onClick={RefreshButtonClicked} />
            </td>
            <td style={{ width:'40px' }}>
              <ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} />
            </td>
            <td style={{ paddingLeft: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                <AddChangeOrderButton disabled={loadingData} onClick={AddChangeOrderButtonClicked} />
              </div>
            </td>
          </tr>
        </table>
      )}

      <ControlGridProjects
        key={lastRefreshedOn.toString()}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        dataList={Projects}
        jobTypes={JobTypes}
        projectStatuses={ProjectStatusList}
        salespersons={Salespersons}
        projectChangeOrders={ProjectChangeOrders}
        focusedRowKey={focusedRowKey}
        onEditRecordClick={clickEditProjectOpen}
        onAddRecordClick={clickAddProjectOpen}
        onRowClicked={GridRowClicked}
        onChangeOrderRecordClick={ChangeOrderClicked}
        onRefreshParentComponent={refreshParentComponent}
      />
      <div style={{ marginTop: '10px', marginBottom: '0px' }}>
        <TabPanel
          width={screenWidthAdjustWidthDetailTab}
          height={340}
          animationEnabled={true}
          swipeEnabled={true}
          tabsPosition={'top'}
          stylingMode={'primary'}>
          <Item title="Monthly Payouts" icon="money">
            <ControlGridDashboardSalesperson
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              dataList={Dashboards}
            />
          </Item>
          <Item title="Commission Payouts" icon="ordersbox">
            <ControlGridCommissionPayouts
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              dataList={ProjectCommissionPayouts}
              commissionPayoutStatuses={CommissionPayoutStatuses}
              onRefreshParentComponent={refreshParentComponent}
            />
          </Item>

          <Item title="Change Orders" icon="ordersbox">
            <ControlGridProjectsChangeOrders
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              dataList={ProjectChangeOrders}
              jobTypes={JobTypes}
              projectStatuses={ProjectStatusList}
            />
          </Item>
        </TabPanel>
      </div>

      <ControlActionDialogConfirmHost
        hideDialog={hideConfirmHostActionDialog}
        onExecute={handleExecuteHostAction}
        action={confirmHostActionState?.action}
        host={confirmHostActionState?.host}
      />
    </div>
  );
}

// function useViewHostDetails(activeUser) {
//   const handleViewHostDetails = useCallback(
//     (host: Project) => {

//     },
//     [activeUser]
//   );

//   return { handleViewHostDetails };
// }

//export default ProjectBrowse;
export default canAccessPage(ProjectBrowse, 'Projects');
