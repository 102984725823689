function InsufficientPrivileges() {
  return (
    <div >
      Insufficient privileges to access requested page.
    </div>

  );
}


export default InsufficientPrivileges;
