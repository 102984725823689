import { useState } from 'react';
import { Button } from 'devextreme-react/button';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import { apiBaseUrlSecurity } from '../../config/appApiConfig';
import { useNavigate } from 'react-router-dom';
import { styles } from '../shared/Styles';


interface ForgotPasswordProps {
  onForgotPasswordSubmitted: () => void;
}

function ForgotPassword({ onForgotPasswordSubmitted }: ForgotPasswordProps) {

  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const navigate = useNavigate();

  function handleCancel() {
    onForgotPasswordSubmitted();
    navigate('home');
    

  }
  function handleSubmit(event) {

    //event.preventDefault();


    const requestOptions_ForgotPassword = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        emailAddress: emailAddress
      })
    };

    //`${apiBaseUrl}Timesheets_GetForBrowseWeeklyView?UserName=${user.userName}&DateStart=${dateStart}`
    if (isRequestSubmitted === false) {
      const url = `${apiBaseUrlSecurity}ForgotPassword?EmailAddress=${emailAddress}`;
      
      fetch(url, requestOptions_ForgotPassword)
        .then(response => response.json())
        .then(data => {
          if (data.response === null || data.response === 'null') {
            //console.log('Forgot password failed');
            setIsRequestSubmitted(false);
          } else {
            //console.log('Forgot password submitted');
            setIsRequestSubmitted(true);
          }

        });
    } else {
      onForgotPasswordSubmitted();
      navigate('home');
    }

  }

  return (
    <div css={styles} >
    <div style={{height: '400px', width: '400px', marginLeft: 'auto', marginRight: 'auto',marginTop: '100px'}}>
        <img src='images/logowelcome.png' alt='PCApps \ PonomaSwapMeet' style={{marginBottom: '40px', width: '300px'}} />
        <h1>Forgot Password</h1>
        <TextBox
              onValueChanged={(data: TextBoxTypes.ValueChangedEvent) => {setEmailAddress(data.value); } }
              style={{marginBottom: '10px'}}
              id='email'
              label='Email Address'
              name='email'
              labelMode='floating'
              height={30}
            />

      
        {(
          isRequestSubmitted ?
            <>
              <span style={{fontSize: '14px', marginLeft: 'auto', marginRight: 'auto'}}>Please check your email for your new temporary password.</span>
              <Button
                id={"dxButtonSignIn"}
                onClick={handleSubmit}
                useSubmitBehavior={true}
              >
                OK
              </Button>
            </>
            :
            <>
              <table>
                <tr>
                  <td>
                    <Button
                      id={"dxSaveButton"}
                      onClick={handleSubmit}
                      useSubmitBehavior={true}
                      width={250}
                    >
                      Reset Password
                    </Button>
                  </td>
                  <td style={{marginRight: '0px', paddingLeft: '30px'}}>
                    <Button
                      id={"dxCloseButton"}
                      onClick={handleCancel}
                      useSubmitBehavior={true}
                      
                      width={120}
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              </table>
              
              
            </>
        )}

    </div>
    </div>
  );

}

export default ForgotPassword;