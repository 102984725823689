import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import ResponsiveBox, {
  Row, Col, Location, 
} from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import RefreshButton from '../../shared/RefreshButton';
import ProgressSpinner from '../../shared/ProgressSpinner';
import ControlGridJobTypes from './CtrGridJobTypes';
import ControlGridJobTypesProjectCosts from './CtrGridJobTypesProjectCosts';

import ControlActionDialogConfirmHost, { useConfirmHostActionDialog } from '../../shared/CtrActionDialogConfirmHost';
import { useJobTypes_GetAll } from '../../../api/JobTypes_GetAll';
import { useJobTypesProjectCosts_GetAll } from '../../../api/JobTypesProjectCosts_GetAll';


import useHostAction from '../../../lib/useHostAction';
import type { JobType } from '../../../api/types/JobType';
import type { JobTypeProjectCost } from '../../../api/types/JobTypeProjectCost';

import { canAccessPage } from '/lib/canAccessPage';
import TabPanel, {Item} from 'devextreme-react/cjs/tab-panel';

const progressSpinnerStyles = css`
  margin-left: 16px;
  vertical-align: middle;
`;


function JobTypeBrowse() {
  // global context
  
  const GridRowClicked = (ts: JobTypeProjectCost) => { 
    // setCurrentJobTypeId(ts.jobTypeId);
  }
  
  
  const {
    data: JobTypesProjectCosts,
    isLoading: loadingJobTypes,
    refetch: refetchJobTypes,
  } = useJobTypesProjectCosts_GetAll();

  // host action
  const { executeAction: handleExecuteHostAction, isLoading: loadingHostAction } = useHostAction(
    refetchJobTypes
  );

  // confirm host action dialog
  const { confirmHostActionState, hideConfirmHostActionDialog } =
    useConfirmHostActionDialog();


  const loadingData = loadingJobTypes || loadingHostAction;

  const [currentJobTypeId, setCurrentJobTypeId] = useState<number>(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)
  
  const [dataLastRefreshedOn, setDataLastRefreshedOn] = useState(new Date(Date.now()));
  const [lastRefreshedOn, setLastRefreshedOn] = useState(new Date(Date.now()));
  const refreshParentComponent = (isRefetchData: boolean) => {
    const t = new Date(lastRefreshedOn);
    t.setSeconds(t.getSeconds() + 3);
    setLastRefreshedOn(t);

    if (isRefetchData) {
      setDataLastRefreshedOn(t);
      refetchJobTypes();
    }
  }
  
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }
    
    window.addEventListener("resize", handleResize)
    
    handleResize()
    
    return () => { 
      window.removeEventListener("resize", handleResize)
    }
  }, [setWindowWidth])

  const screenByWidthFilterSection = (width: number) => ((width < 900) ? 'sm' : 'lg');
  const screenByWidthPaymentTab = (width: number) => ((width < 900) ? 'sm' : 'lg');
  const screenWidthAdjustFilterContainer = ((windowWidth < 900) ? 440 : 450);
  const screenWidthAdjustFilterTextInput = ((windowWidth < 900) ? '125px' : '125px');
  const screenWidthAdjustWidthDetailTab = ((windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%'));
  const screenWidthAdjustHeightDetailTab = ((windowWidth < 900) ? '420' : '420');
  const screenWidthAdjustBody = ((windowWidth !== 0 ? (((windowWidth - 40) / windowWidth) * 100).toString() + 'vw' : '96vw'));


  
  return (
    <div style={{padding: '0px', margin: '0px', width: (screenWidthAdjustBody)}} >
      
        <h1>Setup Job Type Payout Limits</h1>

        <ResponsiveBox
          
          screenByWidth={screenByWidthFilterSection}
          width={screenWidthAdjustFilterContainer}
        >
          <Row ratio={1}></Row>
                    
          <Col ratio={1} baseSize={100} ></Col>
          <Col ratio={1} baseSize={240}  ></Col>
          <Col ratio={1} baseSize={100}  ></Col>

          <Item>
            <Location screen="lg" row={0} col={0}></Location>
            <Location screen="sm" row={0} col={0} ></Location>
            <div style={{marginTop: '5px'}}><RefreshButton  disabled={loadingData} onClick={refetchJobTypes} /><ProgressSpinner css={[progressSpinnerStyles, !loadingData && { display: 'none' }]} /></div>
          </Item>

          {/* <Item>
            <Location screen="lg" row={0} col={1}></Location>
            <Location screen="sm" row={0} col={1} ></Location>
            <div style={{marginTop: '5px'}}><Button id="dxSaveButton" text="contained" type="default" onClick={() => alert('This will update all salesperson default commission rates to the job commission rates')} style={{ marginLeft: '3px' }}> Apply Rates to All Salesperson </Button></div>
          </Item> */}
        </ResponsiveBox>
          
        
        <ControlGridJobTypesProjectCosts
            key={lastRefreshedOn.toString()}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            dataList={JobTypesProjectCosts}
            onRowClicked={GridRowClicked}
            onRefreshParentComponent={refreshParentComponent}
          />
          
        
        
        <ControlActionDialogConfirmHost
          hideDialog={hideConfirmHostActionDialog}
          onExecute={handleExecuteHostAction}
          action={confirmHostActionState?.action}
          host={confirmHostActionState?.host}

        />
      
      
    </div>
  )
}

export default JobTypeBrowse;
//export default canAccessPage(JobTypeBrowse, 'JobTypes');