import { useCallback, useState } from 'react';
import { execCloudHostAction as execCloudHostActionApi } from './execCloudHostAction';
import type { Project } from '../api/types/Project';
import type { FetchLoadState } from '../api/types/FetchLoadState';


const INSTANCE_ACTION_RELOAD_DELAY = 2000;

function useHostAction(refetchQuotes: () => void) {
  const [loadState, setLoadState] = useState<FetchLoadState>({});

  const executeAction = useCallback(
    async (action: string, host: Project) => {
      setLoadState({ state: 'loading' });

      try {
        // call API server
        await execCloudHostActionApi(action, host);

        // refresh host list after delay
        setTimeout(() => {
          // reload data from API server
          refetchQuotes();

          setLoadState({ state: 'success' });
        }, INSTANCE_ACTION_RELOAD_DELAY);
      } catch (error) {
        setLoadState({ state: 'error', error });
      }
    },
    [refetchQuotes]
  );

  return {
    executeAction,
    error: loadState.error,
    isLoading: loadState.state === 'loading',
    isError: loadState.state === 'error',
  };
}

export default useHostAction;
