import { useCallback, useEffect, useState } from 'react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import type { FetchLoadState } from '../api/types/FetchLoadState';
import { msalLoginRedirect } from '/lib/msal';

interface ApiLoaderParams<T> {
  loader: () => Promise<T>;
  onLoaderData: (_data: T) => void;
}

function useApiLoader<T>({ loader, onLoaderData }: ApiLoaderParams<T>) {
  const { inProgress } = useMsal();
  const [loadState, setLoadState] = useState<FetchLoadState>({ update: {} });

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      let ignore = false;
      setLoadState({ state: 'loading', update: loadState.update });

      (async () => {
        try {
          // call loader
          const result = await loader();
          if (!ignore) {
            // call data handler
            onLoaderData(result);

            setLoadState({ state: 'success', update: loadState.update });
          }
        } catch (error) {
          if (!ignore) {
            if (error instanceof InteractionRequiredAuthError) {
              // re-sign in
              msalLoginRedirect();
            } else {
              setLoadState({ state: 'error', error, update: loadState.update });
            }
          }
        }
      })();

      return () => {
        ignore = true;
      };
    }
  }, [inProgress, loadState.update, loader, onLoaderData]);

  const refetch = useCallback(() => {
    setLoadState({ state: 'loading', update: {} });
  }, []);

  return {
    error: loadState.error,
    isLoading: loadState.state === 'loading',
    isError: loadState.state === 'error',
    refetch,
  };
}

export default useApiLoader;
