import { useMemo, useState } from 'react';
import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { AppWebsiteAuthorizations } from './types/AppWebsiteAuthorizations';
import useApiLoader from '../lib/useApiLoader';



async function getAppWebsiteAuthorizations(): Promise<AppWebsiteAuthorizations[]> {
  
  const url = `${apiBaseUrlSecurity}AppWebsiteAuthorizations`;
  
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useAppWebsiteAuthorizations() {
  const [data, setData] = useState<AppWebsiteAuthorizations[]>([]);
  
  const loaderState = useApiLoader<AppWebsiteAuthorizations[]>(
    useMemo(
      () => ({
        loader: async () => {
          
          // call API server
          const data = await getAppWebsiteAuthorizations();
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      []
    )
  );

 
  return { data, ...loaderState };
}

export { getAppWebsiteAuthorizations, useAppWebsiteAuthorizations };
