import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';

async function CheckAndAdd_MSALUserAccount(): Promise<string> {

  const url = `${apiBaseUrlSecurity}CheckAndAdd_MSALUserAccount`;
  const requestData = { };

  
  // call API server
  const data = await sendApiRequestWithAccessToken(url, requestData);
  return data;
}

export { CheckAndAdd_MSALUserAccount };
