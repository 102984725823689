import { ChangeEvent } from 'react';
import React, { useState } from 'react';

import {
    Button,
    DataGrid,
    Column, ColumnFixing,
    Export,
    Editing,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
    DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
    DataGridRef,
    
} from 'devextreme-react/data-grid';


import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import { DashboardQuarterlySalesperson } from '/api/types/DashboardQuarterlySalesperson';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PowersLA-ProjectsExport.xlsx');
    });
  });
};


interface CtrGridDashboardQuarterlySalespersonProps {
  dataList?: DashboardQuarterlySalesperson[];
  payoutGridRef: any;
  windowWidth?: number;
  windowHeight?: number;
  onRowClicked?: (_host: DashboardQuarterlySalesperson) => void;
  onRowDoubleClicked?: (_host: DashboardQuarterlySalesperson) => void;
  //onAddRecordClick?: (_host: DashboardQuarterlySalesperson) => void;
  //onEditRecordClick?: (_host: DashboardQuarterlySalesperson) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;

}

const CtrGridDashboardQuarterlySalesperson = React.memo(function CtrGridDashboardQuarterlySalesperson({
  dataList,
  payoutGridRef,
  windowWidth = 1024,
  windowHeight = 800,
  onRowClicked,
  onRowDoubleClicked,
  //onAddRecordClick,
  //onEditRecordClick,
  onRefreshParentComponent,
}: CtrGridDashboardQuarterlySalespersonProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false)

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked)
    onRefreshParentComponent(false)
  }

  function handleRowClick(e: DashboardQuarterlySalesperson) {
    

    //if (isEditableGrid === false) {
    //onRowClicked(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
      if (item.name === "searchPanel") {
        item.location = 'before';
      }
      if (item.name === "groupPanel") {
        item.location = 'after';
      }
    })
  }
  // --------------------------------------
  

  const rowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      if (['Q1', 'Q2', 'Q3', 'Q4'].includes(e.data.yearQuarterString)) {
        e.rowElement.style.cssText = 'border-bottom: 1px solid #d1d1d1;';
      }
    }
  }
  
  //In-Line grid editing
  const [rows, setRows] = React.useState(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));
  
  const screenWidthAdjustGridWidth = ((windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%'));
  const screenWidthAdjustGridHeight =  '362px';//((windowWidth < 900 || windowHeight < 780 ? (windowHeight - 400 - 230).toString() + 'px' : (windowHeight - 400 - 230).toString() + 'px'));

  return (
    <div style={{width: (screenWidthAdjustGridWidth)}}>
      <DataGrid
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        ref={payoutGridRef}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        // keyExpr="dashboardMonthlyPayoutGuid"
        keyExpr="recordGuid"
        allowColumnResizing={true}
        onExporting={onExporting}
        onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        onRowPrepared={rowPrepared}
        height={(screenWidthAdjustGridHeight)}
        width={'100%'}
        style={{marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={false}
          width={250}
          placeholder="Search..." />
        
        {/* <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column> */}
        <Column dataField="salesPersonUserName" caption='Salesperson' width={160}></Column>
        <Column dataField="yearQuarter" caption='Quarter' width={150}></Column>
        <Column dataField="quarterlyCommissionPayoutAmount" caption='Quarterly Payouts' width={150} format={'$#,##0.00'}></Column>
        <Column dataField="Spacer" caption='' allowFiltering={false} allowEditing={false} allowHeaderFiltering={false}></Column>
                
        <Column dataField="createdOn" caption='Created On'  dataType='datetime' visible={false}></Column>
        <Column dataField="createdBy" caption='Created By' width={120} visible={false}></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime'  visible={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false}></Column>
        
        <ColumnFixing enabled={true} />

        <Summary>
          <TotalItem column="salesPersonUserName" displayFormat='Totals:' />
          <TotalItem column="quarterlyCommissionPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
        </Summary>

        <ColumnChooser
          enabled={false}
          mode={'select'}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={false}
          />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={false} allowExportSelectedData={true} />
        

      </DataGrid>

    </div>
  );



});


export default CtrGridDashboardQuarterlySalesperson;
