import type { Configuration } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    //clientId: '13ffea67-2b3b-4015-b85e-9e26edbf853b',
    clientId: '48a064e8-a8c0-4834-b52d-8349229a348c',
    authority: 'https://login.microsoftonline.com/df02d834-3e71-4d83-97eb-6672a9f9be93',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

//const msalAppApiScope = 'api://1333f849-5fba-4c1c-84c6-456e6277203f/access_as_user';
const msalAppApiScope = 'api://48a064e8-a8c0-4834-b52d-8349229a348c/ProjectCentralReactAccess';

export { msalConfig, msalAppApiScope };
