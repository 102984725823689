import { useMemo, useState } from 'react';
import { apiBaseUrlSecurity } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import useApiLoader from '../lib/useApiLoader';
import type { Roles } from './types/Roles';



async function UsersOrgsRoles_GetForUserGuid(userGuid: string): Promise<Roles[]> {
    
  const url = `${apiBaseUrlSecurity}UsersOrgsRoles_GetForUserGuid?userGuid=${(userGuid === null || userGuid === undefined || undefined === '' ? '00000000-0000-0000-0000-000000000000' : userGuid)}`;
  
  // call API server
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useUsersOrgsRoles_GetForUserGuid(userGuid: string) {
  const [data, setData] = useState<Roles[]>([]);

  const loaderState = useApiLoader<Roles[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await UsersOrgsRoles_GetForUserGuid(userGuid);
          return data;
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [userGuid]
    )
  );
 
  return { data, ...loaderState };
}

export { UsersOrgsRoles_GetForUserGuid, useUsersOrgsRoles_GetForUserGuid };
