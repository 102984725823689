//import { isUsePCAppsSecurity } from '../config/appApiConfig';

async function sendApiRequest(accessToken: string, url: string, requestData: unknown, method = 'POST') {
  if (!accessToken) return null;
  
  const body = method === 'POST' ? JSON.stringify(requestData) : null;
  
  // create web request
  try {
    const res = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body,
    });
    if (!res.ok) throw new Error('Request failed');

    const data = await res.json();
    return data;
  } catch (err) {
    //console.log({err})
    return null;
  }
}

export { sendApiRequest };
